import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import { Box, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import withThemeProviderSubscribe from './WithThemeProviderSubscribe';
import { useLocalStorage } from '../hooks';
import globalStore from '../store';

const useStyles = makeStyles()((theme) => ({
  basePage: {
    background: theme.palette.background.default,
    color: theme.palette.typography.primary,
    minHeight: '100vh',
  },
}));

const AppBasePageProvider = ({ children }) => {
  const basePageRef = useRef();
  const { classes } = useStyles();
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const [userPreferencesSidebar] = useLocalStorage('user-preferences-sidebar');

  // set background wrapper to match theme background
  document.querySelector('.main-content').style.backgroundColor =
    theme.palette.background.default;

  const isSideBarExpanded =
    userPreferencesSidebar?.[globalSnap?.userId]?.sidebarExpanded || false;

  useEffect(() => {
    // set marginLeft based on sidebar preferences, useful in page jumps
    const applicationElement = basePageRef.current?.parentNode?.parentNode;
    if (applicationElement) {
      applicationElement.style.marginLeft = isSideBarExpanded
        ? '240px'
        : '73px';
    }
  }, [isSideBarExpanded]);

  return (
    <Box ref={basePageRef} px={3} pt={11} className={classes.basePage}>
      {children}
    </Box>
  );
};

AppBasePageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withThemeProviderSubscribe(AppBasePageProvider);
