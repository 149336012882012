import { useSnapshot, proxy } from 'valtio';
import { derive } from 'derive-valtio';
import { devtools } from 'valtio/utils';
import TokenState from './TokenStateEnum';
import tokenStore from './tokenStore';
import toProperCase from './properCase';
import Permissions from './permissions';
import Authorization from './authorization';
import userHasAccess from './userHasAccess';
import { emailHelp, emailValidation } from './email';
import useTitle from './hooks/useTitle';
import useLazyState from './hooks/useLazyState';
import sendEvent from './sendEvent';
import amplifyConfig from './amplifyConfig';

import {
  getColour,
  rowFilter,
  getDifficultyLabel,
  statusChangeOptions,
  statusEnum,
  getColourHex,
  getCategoryDescription,
  getInitials,
  removeWhiteSpace,
  removeDashes,
  isValidMobile,
  reMapAgeLabel,
  getTextColorByBackgroundColor,
} from './utils';
import { getCortexLevels, cortexSort, getCortexCellColor } from './cortex';
import owasp, {
  passwordConfirmHelp,
  passwordConfirmValidation,
  passwordValidation,
  passwordHelp,
  passwordRuleMessages,
} from './password';
import removeSpecialChars from './removeSpecialChars';
import {
  allDays,
  allMonths,
  allYears,
  getDateTimeZone,
  getDateTimeZoneFormatted,
  getDateTimeZoneCustom,
  getDifferenceInMinutes,
  formatMinutes,
  getFormattedDuration,
  formatMinutesToHours,
} from './dateUtils';
import globalStore, { resetGlobalStore } from '../store';
import { getAvatarColor, getAvatarName } from './avatarUtils';
import setAppMarginRightByIds from './layout';
import {
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
  infoToastMessage,
  stickyToastMessage,
  ToastContainer,
} from './toast';
import { downloadFile } from './fileUtils';

export {
  TokenState,
  tokenStore,
  toProperCase,
  Permissions,
  Authorization,
  emailHelp,
  emailValidation,
  passwordConfirmHelp,
  passwordConfirmValidation,
  passwordValidation,
  passwordHelp,
  passwordRuleMessages,
  userHasAccess,
  owasp,
  removeSpecialChars,
  getDifficultyLabel,
  getColour,
  getTextColorByBackgroundColor,
  rowFilter,
  statusChangeOptions,
  statusEnum,
  getColourHex,
  getCategoryDescription,
  getInitials,
  removeWhiteSpace,
  removeDashes,
  isValidMobile,
  reMapAgeLabel,
  useTitle,
  useLazyState,
  sendEvent,
  allYears,
  allMonths,
  allDays,
  amplifyConfig,
  globalStore,
  useSnapshot,
  derive,
  proxy,
  resetGlobalStore,
  devtools,
  getAvatarColor,
  getAvatarName,
  setAppMarginRightByIds,
  getDateTimeZone,
  getDateTimeZoneFormatted,
  getDateTimeZoneCustom,
  getDifferenceInMinutes,
  formatMinutes,
  getFormattedDuration,
  formatMinutesToHours,
  cortexSort,
  getCortexLevels,
  getCortexCellColor,
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
  infoToastMessage,
  stickyToastMessage,
  ToastContainer,
  downloadFile,
};
