import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import globalEventStore from '../../store/eventStore';

export const triggerGlobalEvent = (event) => {
  if (!globalEventStore.events.includes(event)) {
    globalEventStore.events.push(event);
  }
};

export const clearGlobalEvent = (event) => {
  globalEventStore.events = globalEventStore.events.filter((e) => e !== event);
};

export const useGlobalEventListener = (eventName, callback) => {
  const snapshot = useSnapshot(globalEventStore);

  useEffect(() => {
    if (snapshot.events.includes(eventName)) {
      callback();
      clearGlobalEvent(eventName);
    }
  }, [snapshot.events, eventName, callback]);
};
