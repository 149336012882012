import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FdTypography from '../FdTypography';
import { getPreSignedUrl } from '../../shared/image';

const useStyles = makeStyles()((theme) => ({
  urlLink: {
    '& a': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));

function FdFileAttachment({ name, onClickAttachment }) {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState('');
  const id = shortid.generate();

  useEffect(() => {
    async function fetchUrl() {
      const signedUrl = await getPreSignedUrl(name);
      setFileUrl(signedUrl);
      setLoading(false);
    }
    fetchUrl();
  }, [name]);

  return (
    <Box display="flex" alignItems="center">
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <FdTypography
          variant="body1"
          className={classes.urlLink}
          data-cy="download-link"
        >
          <a
            id={id}
            key={id}
            href={fileUrl}
            onClick={() => onClickAttachment?.(id, name)}
          >
            {name}
          </a>
        </FdTypography>
      )}
    </Box>
  );
}

FdFileAttachment.propTypes = {
  name: PropTypes.string.isRequired,
  onClickAttachment: PropTypes.func,
};

FdFileAttachment.defaultProps = {
  onClickAttachment: undefined,
};

export default FdFileAttachment;
