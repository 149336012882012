import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FdButton from '../FdButton';
import { COMMON_VARIANT_COLORS } from '../../constants';

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: 'none',
    border: `1px solid ${theme.palette.table.border}`,
    borderRadius: '4px',
    background: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    '&:before': {
      display: 'none',
    },
  },
  summary: {
    padding: 0,
    minHeight: 0,
    [`&.${classes.expanded}`]: {
      margin: '0 0 16px',
      minHeight: 0,
    },
  },
  details: {
    padding: 0,
    color: theme.palette.typography.secondary,
  },
  summaryContent: {
    margin: 0,
    [`&.${classes.expanded}`]: {
      margin: 0,
    },
    color: ({ color }) => color,
  },
  expanded: {},
}));

function FdAccordion({
  summary,
  content,
  actions,
  open,
  showToggleButton,
  onShowEdit,
  startAdornment,
  startAdornmentIcon,
  endAdornment,
  endAdornmentIcon,
  onExpandCollapse,
  variant,
  ...props
}) {
  const theme = useTheme();
  const { classes } = useStyles({
    backgroundColor: variant
      ? COMMON_VARIANT_COLORS[variant]
      : theme.palette.background.paper,
    color: variant
      ? theme.palette.common.black
      : theme.palette.typography.primary,
  });
  const [expanded, setExpanded] = useState(open);

  const handleChange = () => {
    setExpanded(!expanded);
    onExpandCollapse?.(!expanded);
    if (onShowEdit) {
      onShowEdit();
    }
  };

  return (
    <Accordion
      square
      expanded={expanded}
      classes={{ root: classes.root }}
      {...props}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        classes={{
          root: classes.summary,
          content: classes.summaryContent,
          expanded: classes.expanded,
        }}
        onClick={handleChange}
      >
        <Box
          display="flex"
          justifyContent={!startAdornment ? 'space-between' : ''}
          width="100%"
          alignItems="center"
        >
          <Box display="flex" width="100%">
            {startAdornmentIcon && (
              <span
                style={{
                  color: theme.palette?.[variant]?.dark,
                  marginRight: '8px',
                }}
              >
                {startAdornmentIcon()}
              </span>
            )}
            {startAdornment && (
              <Box mr={1}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Box>
            )}
            {summary()}
          </Box>
          {showToggleButton && (
            <FdButton
              variant="secondary"
              size="small"
              onClick={handleChange}
              aria-label="toggle-challenge-info-accordion"
            >
              {expanded ? 'Hide' : 'Show'}
            </FdButton>
          )}
          {endAdornmentIcon && endAdornmentIcon()}
          {endAdornment && (
            <Box ml={1}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          )}
        </Box>
        <AccordionActions onClick={(e) => e.stopPropagation()}>
          {actions}
        </AccordionActions>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        <Box width="100%">{content()}</Box>
      </AccordionDetails>
    </Accordion>
  );
}

FdAccordion.defaultProps = {
  onShowEdit: () => {},
  startAdornment: undefined,
  endAdornment: undefined,
  startAdornmentIcon: undefined,
  endAdornmentIcon: undefined,
  showToggleButton: true,
  open: false,
  actions: undefined,
  onExpandCollapse: () => undefined,
  variant: undefined,
};

FdAccordion.propTypes = {
  content: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  startAdornment: PropTypes.bool,
  endAdornment: PropTypes.bool,
  startAdornmentIcon: PropTypes.func,
  endAdornmentIcon: PropTypes.func,
  open: PropTypes.bool,
  showToggleButton: PropTypes.bool,
  onShowEdit: PropTypes.func,
  actions: PropTypes.node,
  onExpandCollapse: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
};

export default FdAccordion;
