import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import nivoLight from './nivo-graphs/light';
import nivoDark from './nivo-graphs/dark';

const makeTheme = (theme) => {
  return {
    ...theme,
    nivoGraph: theme?.palette?.type === 'dark' ? nivoDark : nivoLight,
  };
};

export default (theme) => responsiveFontSizes(createTheme(makeTheme(theme)));
