import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import { Typography, Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from 'lodash';
import { SearchBox } from './ToolbarActions';
import Button from '../FdButton';
import FilterBar from './FilterBar';
import useStyle from './styles';
import { getIdFromText } from '../../shared/utils';
import FdTooltip from '../FdTooltip';

function Toolbar({
  apiRef,
  settings,
  defaultMuiToolbarSettings,
  rowClickable,
  searchProps,
}) {
  const {
    title,
    titleTooltip,
    titleTooltipIcon,
    subTitle,
    searchBox,
    actions,
    bulkActions,
    headerCustomElements,
    headerActions,
    headerElements,
    selection,
  } = settings;
  const {
    showMuiDefaultToolbar, // hide the entire toolbar
    columnsButton, // true to show, false to hide
    filterButton,
    densityButton,
    exportButton,
  } = defaultMuiToolbarSettings;

  const { classes } = useStyle({ rowClickable });
  const [searching, setSearching] = useState(false);
  const hasSelectedItems = apiRef.current.state?.selection;
  const activeFilters = _.chain(apiRef)
    .get(['current', 'state', 'filter', 'filterModel', 'items'], [])
    .filter((x) => x.value)
    .value();
  const hasFilters = !_.isEmpty(activeFilters);
  const showSelectedToolbar = _.isEmpty(hasSelectedItems) || !selection;
  const tableHeading = showSelectedToolbar
    ? title
    : `${hasSelectedItems.length} Selected`;
  const showToolbarSubComponents =
    showMuiDefaultToolbar ||
    headerElements ||
    headerCustomElements ||
    headerActions ||
    searchBox;

  return (
    <>
      <GridToolbarContainer
        className={
          showSelectedToolbar ? classes.rawToolbar : classes.styledToolbar
        }
      >
        {tableHeading && (
          <Box>
            <Box mt={1.5} ml={1} display="flex" alignItems="center">
              <Typography
                variant="h6"
                style={{ fontWeight: 400 }}
                data-cy={getIdFromText(tableHeading, 'table-heading')}
              >
                {tableHeading}
              </Typography>
              {titleTooltip && (
                <FdTooltip title={titleTooltip} arrow>
                  <IconButton size="large">
                    {titleTooltipIcon || <InfoOutlinedIcon />}
                  </IconButton>
                </FdTooltip>
              )}
            </Box>
            <Box display="flex" ml={1}>
              {subTitle}
            </Box>
          </Box>
        )}
        {showToolbarSubComponents && (
          <Box
            className="flex items-center justify-between w-full mx-2"
            sx={{ height: '64px' }}
          >
            {showMuiDefaultToolbar && (
              <Box className="flex items-center justify-center">
                {columnsButton && (
                  <GridToolbarColumnsButton className={classes.toolbarButton} />
                )}
                {filterButton && (
                  <GridToolbarFilterButton className={classes.toolbarButton} />
                )}
                {densityButton && (
                  <GridToolbarDensitySelector
                    className={classes.toolbarButton}
                  />
                )}
                {exportButton && (
                  <GridToolbarExport className={classes.toolbarButton} />
                )}
              </Box>
            )}
            <Box className="flex items-center justify-end">
              {headerElements && <Box ml={3}>{headerElements}</Box>}
              {searchBox && (
                <SearchBox
                  apiRef={apiRef}
                  setSearching={setSearching}
                  searchProps={searchProps}
                  data-cy={getIdFromText(tableHeading, 'table-search')}
                />
              )}
              {headerCustomElements &&
                headerCustomElements.map(
                  ({ CustomElement, styleClass }, index) => (
                    <Box
                      mx={1}
                      key={index}
                      className={styleClass || classes.styledSelect}
                    >
                      <CustomElement />
                    </Box>
                  ),
                )}
              {!_.isEmpty(hasSelectedItems) &&
                bulkActions &&
                hasSelectedItems.length > 1 &&
                actions
                  .filter(({ label }) => label !== 'Edit')
                  .map(({ label, onClick }, index) => (
                    <Box mx={1} key={index}>
                      <Button
                        onClick={() => {
                          onClick(hasSelectedItems);
                        }}
                        variant="secondary"
                      >
                        {`${label} ${
                          apiRef.current.getRowsCount() ===
                          hasSelectedItems.length
                            ? 'All'
                            : 'Selected'
                        }`}
                      </Button>
                    </Box>
                  ))}
              {headerActions &&
                headerActions.map(({ label, onClick, show = true }, index) => (
                  <Box mx={1} key={index}>
                    {show && (
                      <Button
                        onClick={onClick}
                        data-cy={getIdFromText(label, 'action-button')}
                      >
                        {label}
                      </Button>
                    )}
                  </Box>
                ))}
            </Box>
          </Box>
        )}
      </GridToolbarContainer>
      {hasFilters && !searching && <FilterBar apiRef={apiRef} />}
    </>
  );
}

Toolbar.propTypes = {
  settings: PropTypes.shape({
    hide: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleTooltip: PropTypes.node,
    titleTooltipIcon: PropTypes.node,
    subTitle: PropTypes.node,
    searchBox: PropTypes.bool,
    bulkActions: PropTypes.bool,
    selection: PropTypes.bool,
    setFilter: PropTypes.func,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
    headerCustomElements: PropTypes.arrayOf(
      PropTypes.shape({
        CustomElement: PropTypes.element,
      }),
    ),
    headerActions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
        show: PropTypes.bool,
      }),
    ),
    headerElements: PropTypes.element,
  }),
  apiRef: PropTypes.shape({ current: PropTypes.object }),
  defaultMuiToolbarSettings: PropTypes.shape({
    showMuiDefaultToolbar: PropTypes.bool,
    columnsButton: PropTypes.bool,
    filterButton: PropTypes.bool,
    densityButton: PropTypes.bool,
    exportButton: PropTypes.bool,
  }).isRequired,
  rowClickable: PropTypes.bool,
  searchProps: PropTypes.shape({
    openSearch: PropTypes.bool,
    setOpenSearch: PropTypes.func,
    searchText: PropTypes.string,
    setSearchText: PropTypes.func,
  }).isRequired,
};

Toolbar.defaultProps = {
  apiRef: {},
  settings: {
    hide: false,
    title: '',
    titleTooltip: undefined,
    titleTooltipIcon: undefined,
    subTitle: undefined,
    searchBox: false,
    bulkActions: false,
    selection: false,
    setFilter: () => {},
    actions: [],
    headerActions: [],
  },
  rowClickable: false,
};

export default Toolbar;
