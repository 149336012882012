import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles()((theme) => ({
  reactMdeRoot: {
    '& .react-mde': {
      borderRadius: '4px',
    },
    '& .mde-header': {
      background: theme.palette.background.paper,
      '& .mde-tabs': {
        '& button': {
          fontSize: '0.75rem',
          lineHeight: '1.5rem',
          padding: '8px 16px',
          margin: 0,
          borderRight: `1px solid ${ColorPalette.grey03}`,
          color: theme.palette.typography.primary,
          '&:first-child': {
            marginLeft: 0,
          },
          '&.selected': {
            border: 0,
            borderRight: `1px solid ${ColorPalette.grey03}`,
            backgroundColor: alpha(theme.palette.primary.main, 0.3),
          },
          '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        },
      },
    },
    '& .mde-header-group': {
      borderRight: `1px solid ${ColorPalette.grey03}`,
    },
    '& [class*="MuiSvgIcon-root"]': {
      color: theme.palette.iconography.primary,
    },
    '& .mde-text': {
      background: theme.palette.inputs.background,
      color:
        (theme.palette.typography && theme.palette.typography.primary) ||
        ColorPalette.black00,
      '&:focus-visible': {
        outline: 'none !important',
      },
    },
    '& .mde-preview': {
      background: theme.palette.inputs.background,
    },
    '& ul': {
      listStyle: 'disc',
    },
    '& .mde-textarea-wrapper': {
      backgroundColor: theme.palette.background.menu,
    },
  },
  reactMdeError: {
    '& .react-mde': {
      borderColor: ColorPalette.red00,
    },
  },
  toolbarDense: {
    '& ul.mde-header-group': {
      borderTop: `1px solid ${ColorPalette.grey03}`,
      borderBottom: `1px solid ${ColorPalette.grey03}`,
      marginTop: '-1px',
    },
  },
  labelRoot: {
    lineHeight: '1.5rem',
    fontWeight: 500,
    color: theme.palette.typography && theme.palette.typography.primary,
    overflowWrap: 'break-word',
  },
  optionalLabel: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: theme.palette.typography && theme.palette.typography.secondary,
  },
  errorText: {
    color: ColorPalette.red00,
  },
  helpText: {
    paddingTop: '4px',
    color: theme.palette.typography.secondary,
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
