import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import _ from 'lodash';
import { ReactQueryClient } from '../clients';

const initialValues = {
  userId: undefined,
  user: undefined,
  userType: undefined,
  userEmail: undefined,
  userName: undefined,
  userHmacId: undefined,
  userAlias: undefined,
  orgProducts: undefined,
  loading: false,
  error: undefined,
  orgImage: undefined,
  orgId: undefined,
  orgAlias: undefined,
  orgName: undefined,
  orgPricingTier: undefined,
  orgMaxInvitees: undefined,
  isAAFCOrg: false,
  isCSCOrg: false,
  theme: undefined,
  logoS3ImageUrl: undefined,
  hasManagerialPermissions: false,
  isAffiliated: false,
  permissions: undefined,
  features: {
    hasSkillTarget: false,
    hasWorkForce: false,
  },
  userAvatarKey: undefined,
  isSmallScreen: false,
};

// store definition
const globalStore = proxy({
  ...initialValues,
});

export const resetGlobalStore = () => {
  //  deep clone to allows Valtio to update correctly when the value of a key is an object
  const resetObj = _.cloneDeep(initialValues) || {};
  Object.keys(resetObj).forEach((key) => {
    globalStore[key] = resetObj[key];
  });
  // remove all cached queries on logout
  ReactQueryClient.removeQueries();
};

// NOTE: uncomment to use redux devtools
devtools(globalStore);

export default globalStore;
