import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  ListItemText,
  Checkbox,
  Paper,
  ListItem,
} from '@mui/material/';
import { makeStyles } from 'tss-react/mui';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ColorPalette } from '../../shared/colors';
import FdTypography from '../FdTypography';

const SELECT_ALL_VALUE = 'select-all';

const useStyles = makeStyles()((theme, _params, classes) => ({
  formControl: {
    [`& .${classes.autocompleteRoot}`]: {
      [`& .${classes.autoCompleteInputRoot}`]: {
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 0,
        '& .labelTitle': {
          maxWidth: '95%',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '& .labelCount': {
          paddingLeft: 5,
        },
      },
    },
    '& [class*="MuiSvgIcon-root"]': {
      color: '#000',
    },
    '& [class*="MuiAutocomplete-root-autocompleteRoot"]': {
      '& [class*="MuiAutocomplete-inputRoot"]': {
        color: '#000',
      },
    },
  },
  autoCompleteInputRoot: {},
  errorText: {
    color: ColorPalette.red00,
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
  formHelperSpacing: {
    marginLeft: '0',
    marginTop: '0',
  },
  option: {
    padding: 5,
  },
  listItemIconRoot: {
    margin: 0,
    padding: 0,
    minWidth: 0,
  },
  listItemRoot: {
    padding: 0,
    margin: 0,
  },
}));

const FdAutocomplete = ({
  selectAllLabel,
  multiple,
  options,
  optionLabel,
  optionValue,
  helperText,
  error,
  chipCountWhenClosed,
  width,
  fullWidth,
  id,
  defaultSelected,
  onChange,
  placeholder,
  label,
  optional,
  disabled,
  renderTags,
  renderInput,
  ListItemTextRenderer,
  renderTagsFromMUI,
  ...props
}) => {
  const { classes } = useStyles();
  const [selectedOptions, setSelectedOptions] = useState(defaultSelected || []);
  const [searchedVal, setSearchedVal] = useState(undefined);

  const handleToggleOption = (selected) => setSelectedOptions(selected);

  const handleClearOptions = () => {
    setSearchedVal(undefined);
    setSelectedOptions([]);
  };

  const getOptionSelected = (option, anotherOption) =>
    option[optionValue] === anotherOption[optionValue];

  const filter = createFilterOptions();

  const getOptionLabel = (option) => option[optionLabel] || option;

  const runFilter = () => {
    if (searchedVal) {
      const params = {};
      params.inputValue = searchedVal;
      params.getOptionLabel = getOptionLabel;
      const filtered = filter(options, params);
      return filtered;
    }
    return options;
  };

  const onSelectAll = (isSelected) => {
    if (isSelected) {
      if (searchedVal) {
        setSelectedOptions(runFilter());
        onChange(runFilter());
      } else {
        setSelectedOptions(options);
        onChange(options);
      }
    } else {
      handleClearOptions();
      onChange([]);
    }
  };

  const allSelected = options?.length === selectedOptions?.length;

  const handleToggleSelectAll = () => {
    onSelectAll(!allSelected);
  };

  const handleRenderInput = (params) => (
    <TextField
      {...params}
      variant="outlined"
      error={error}
      placeholder={selectedOptions?.length ? '' : placeholder}
    />
  );
  const handleInputChange = (e, value, reason) => {
    if (reason === 'input') {
      setSearchedVal(value);
    } else {
      setSearchedVal(undefined);
    }
  };

  const handleRenderTags = (tagValue) => {
    let newTagValue = [];
    selectedOptions?.forEach((newTag) => newTagValue.push(newTag[optionLabel]));
    newTagValue = newTagValue.slice(0, chipCountWhenClosed)?.join(', ');

    return (
      <>
        <span className="labelTitle">{`${newTagValue}  `}</span>
        {tagValue && tagValue.length > chipCountWhenClosed ? (
          <span className="labelCount">
            {`+ ${tagValue.length - chipCountWhenClosed} more `}
          </span>
        ) : null}
      </>
    );
  };

  const handleChange = (e, selectedValues, reason) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (
        multiple &&
        selectedValues?.find(
          (option) => option[optionValue] === SELECT_ALL_VALUE,
        )
      ) {
        handleToggleSelectAll();
      } else {
        handleToggleOption(selectedValues);
        onChange(selectedValues);
      }
    } else if (reason === 'clear') {
      handleClearOptions();
      onChange([]);
    }
  };

  const CustomPaper = (paperProps) => (
    <Paper elevation={8} style={{ maxHeight: 224 }} {...paperProps} />
  );

  const handleFilterOptions = (opt, params) => {
    const filtered = filter(opt, params);
    return [
      { [optionLabel]: selectAllLabel, [optionValue]: SELECT_ALL_VALUE },
      ...filtered,
    ];
  };

  const Row = (_props) => {
    const { data, index, style } = _props;
    const option = data[index];
    const optionForRender = { ...option.optionElements };
    const selectAllProps =
      option.optionElements?.[optionValue] === SELECT_ALL_VALUE
        ? // To control the state of 'select-all' checkbox
          { checked: allSelected }
        : {};
    return (
      <div
        style={{
          ...style,
        }}
      >
        <ListItem
          component="div"
          classes={{ root: classes.listItemRoot }}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Checkbox
            color="primary"
            checked={option.selected}
            indeterminate={
              option.optionElements?.id === SELECT_ALL_VALUE
                ? selectedOptions.length > 0 &&
                  selectedOptions.length < options.length
                : false
            }
            onClick={(e) => {
              data[index].selected = e.target.checked;
              // select-all set bulk assignment
              if (option.optionElements?.id === SELECT_ALL_VALUE) {
                if (e.target.checked) {
                  setSelectedOptions(runFilter());
                  onChange(runFilter());
                } else {
                  setSelectedOptions([]);
                  onChange([]);
                }
                return;
              }
              if (e.target.checked) {
                setSelectedOptions((prevValue) => [
                  ...prevValue,
                  option.optionElements,
                ]);
                onChange([...selectedOptions, option.optionElements]);
              } else {
                // remove from selectedOptions
                setSelectedOptions((prevValue) =>
                  prevValue.filter((pv) => pv.id !== option.optionElements.id),
                );
                onChange([
                  ...selectedOptions.filter(
                    (so) => so.id !== option.optionElements.id,
                  ),
                ]);
              }
            }}
            {...selectAllProps}
          />
          {ListItemTextRenderer ? (
            <ListItemTextRenderer option={optionForRender} />
          ) : (
            <ListItemText
              primary={getOptionLabel(option)}
              style={{ width: '100%' }}
            >
              {' '}
            </ListItemText>
          )}
        </ListItem>
      </div>
    );
  };

  function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const ListBoxRenderer = forwardRef(function ListboxComponent(_props, ref) {
    const { children } = _props;
    const itemData = [];
    children.forEach((item) => {
      const optionItem = options.find(
        (o) => o[optionLabel] === item.props.children, // check for label is same
      );
      const selected = !!selectedOptions.find(
        (so) => so?.id === optionItem?.id,
      );
      // include itemData with custom options
      itemData.push({
        ...item,
        ...optionItem,
        optionElements: {
          ...(optionItem || {
            [optionLabel]: selectAllLabel,
            [optionValue]: SELECT_ALL_VALUE,
          }),
        },
        selected,
      });
    });
    const itemCount = itemData.length;
    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <VariableSizeList
          itemData={itemData}
          height={150}
          itemCount={itemCount}
          itemSize={() => 55}
          ref={gridRef}
          width="100%"
          {...props}
        >
          {Row}
        </VariableSizeList>
      </div>
    );
  });

  return (
    <Box
      display={fullWidth ? 'flex' : 'inline-flex'}
      flexDirection="column"
      width={fullWidth ? 'auto' : width}
    >
      {label && (
        <Box className="flex gap-1 items-center">
          <FdTypography variant="subtitle1">{label}</FdTypography>
          {optional && (
            <FdTypography variant="captiontext1" color="secondary">
              Optional
            </FdTypography>
          )}
        </Box>
      )}

      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={error}
        disabled={disabled}
      >
        {multiple ? (
          <Autocomplete
            {...props}
            id={id}
            disabled={disabled}
            value={selectedOptions}
            options={options}
            classes={{
              root: classes.autocompleteRoot,
              hasPopupIcon: classes.hasPopupIcon,
              option: classes.option,
            }}
            onInputChange={handleInputChange}
            PaperComponent={CustomPaper}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            filterOptions={handleFilterOptions}
            onChange={handleChange}
            renderInput={renderInput || handleRenderInput}
            ListboxComponent={ListBoxRenderer}
            disableCloseOnSelect
            disableListWrap
            multiple
            {...(renderTagsFromMUI
              ? undefined
              : { renderTags: renderTags || handleRenderTags })}
          />
        ) : (
          <Autocomplete
            {...props}
            id={id}
            options={options}
            getOptionLabel={getOptionLabel}
            renderInput={handleRenderInput}
            onChange={handleChange}
          />
        )}

        {helperText && (
          <Box mt={0.5}>
            <FormHelperText
              classes={{
                root: classes.formHelperSpacing,
              }}
              id={`${id}-helper-text`}
            >
              {helperText}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

FdAutocomplete.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  multiple: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  chipCountWhenClosed: PropTypes.number,
  /** To grow fullwidth of parent */
  fullWidth: PropTypes.bool,
  /** Custom width */
  width: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  optional: PropTypes.bool,
  selectAllLabel: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  renderTags: PropTypes.func,
  renderInput: PropTypes.func,
  ListItemTextRenderer: PropTypes.node,
  renderTagsFromMUI: PropTypes.bool,
};

FdAutocomplete.defaultProps = {
  error: false,
  disabled: false,
  defaultSelected: undefined,
  multiple: false,
  helperText: undefined,
  chipCountWhenClosed: 1,
  fullWidth: false,
  width: '276px',
  onChange: () => {},
  placeholder: 'Select',
  label: undefined,
  selectAllLabel: undefined,
  optionLabel: 'label',
  optionValue: 'value',
  renderTags: undefined,
  renderInput: undefined,
  ListItemTextRenderer: undefined,
  renderTagsFromMUI: false,
  optional: false,
};

export default FdAutocomplete;
