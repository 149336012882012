import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link } from '@mui/material';
import { FdBreadcrumbs, FdPageHeading, FdSkeleton } from '../components';

const BasePage = ({
  type,
  pageAdornment,
  heading,
  headingAdornment,
  breadCrumbs,
  currentPageBreadcrumbLabel,
  linkComponent,
  renderBreadCrumbAsButton,
  children,
  loading,
  ...rest
}) => (
  <Box className="w-full">
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={breadCrumbs.length > 0 ? 2 : 0}
    >
      {breadCrumbs.length > 0 && (
        <FdBreadcrumbs
          linkChain={breadCrumbs}
          currentPage={currentPageBreadcrumbLabel}
          linkComponent={linkComponent}
          renderAsButton={renderBreadCrumbAsButton}
        />
      )}
      {pageAdornment}
    </Box>
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        width={headingAdornment ? '75%' : '100%'}
        styles={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        <FdSkeleton loading={loading}>
          <FdPageHeading
            type={type}
            noWrap
            data-cy={rest['data-cy'] || 'base-page'}
          >
            {heading}
          </FdPageHeading>
        </FdSkeleton>
      </Box>
      {headingAdornment}
    </Box>
    {children}
  </Box>
);

BasePage.propTypes = {
  type: PropTypes.string,
  pageAdornment: PropTypes.node,
  heading: PropTypes.node,
  children: PropTypes.node.isRequired,
  breadCrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  currentPageBreadcrumbLabel: PropTypes.string,
  linkComponent: PropTypes.object,
  headingAdornment: PropTypes.element,
  renderBreadCrumbAsButton: PropTypes.bool,
  loading: PropTypes.bool,
};

BasePage.defaultProps = {
  pageAdornment: <></>,
  heading: '',
  currentPageBreadcrumbLabel: '',
  breadCrumbs: [],
  linkComponent: Link,
  headingAdornment: null,
  renderBreadCrumbAsButton: false,
  loading: false,
  type: '',
};

export default BasePage;
