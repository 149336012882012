/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import S3Image from './S3Image';
import globalStore from '../../store';
import FdLogo from './FdLogo';

function Logo({ logo, isWhiteLabelled, isIcon }) {
  const globalSnap = useSnapshot(globalStore);
  // save logo s3 image url to global store
  const setGlobalStoreLogoImageUrl = (url) => {
    globalStore.logoS3ImageUrl = url;
  };

  if (logo && isWhiteLabelled)
    return (
      <S3Image
        image={logo}
        size={{ height: '225px' }}
        s3ImageUrl={globalSnap.logoS3ImageUrl}
        onImageUrlFromS3={setGlobalStoreLogoImageUrl}
      />
    );
  return <FdLogo isIcon={isIcon} />;
}

Logo.propTypes = {
  logo: PropTypes.string,
  isWhiteLabelled: PropTypes.bool.isRequired,
  isIcon: PropTypes.bool,
};

Logo.defaultProps = {
  logo: '',
  isIcon: false,
};

export default Logo;
