import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function SearchBox({ apiRef, setSearching, searchProps }) {
  const { openSearch, setOpenSearch, searchText, setSearchText } = searchProps;
  const updateSearchValue = React.useMemo(() => {
    return _.debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(' ').filter((word) => word !== ''),
      );
    }, 200);
  }, [apiRef]);

  const handleSearchValueChange = (event) => {
    const newValue = event.target.value;
    setSearchText(newValue);
    updateSearchValue(newValue);
  };

  return !openSearch ? (
    <IconButton
      aria-label="search"
      onClick={() => {
        setSearchText('');
        setOpenSearch(true);
      }}
      size="large"
    >
      <SearchIcon />
    </IconButton>
  ) : (
    <TextField
      id="search"
      placeholder="Search"
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            data-cy="clear search"
            onClick={() => {
              setSearchText(' ');
              apiRef.current.setQuickFilterValues('');
              setOpenSearch(false);
              setSearching(false);
            }}
            size="large"
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
      onChange={handleSearchValueChange}
      autoFocus
    />
  );
}

SearchBox.propTypes = {
  apiRef: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
  setSearching: PropTypes.func.isRequired,
  searchProps: PropTypes.shape({
    openSearch: PropTypes.bool,
    setOpenSearch: PropTypes.func,
    searchText: PropTypes.string,
    setSearchText: PropTypes.func,
  }).isRequired,
};

export default SearchBox;
