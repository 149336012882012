/* FdChip */
import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';
import { fdProColors } from '../../themes/fd-pro-colors';

const useStyles = makeStyles()(() => ({
  root: {
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
    alignItems: 'center',
    textAlign: 'center',
    display: 'inline-flex !important',
    color: 'rgba(0,0,0,87)',
    lineHeight: 'initial',
    '&.MuiChip-clickable:hover': {
      backgroundColor: ColorPalette.lightGrey00,
    },
    '&.MuiChip-clickable:focus': {
      backgroundColor: ColorPalette.lightBlue00,
    },
  },
  labelTypeRoot: {
    borderRadius: '4px',
    color: 'rgba(0,0,0,87)',
    fontWeight: 500,
    '&.MuiChip-clickable:hover': {
      backgroundColor: ColorPalette.lightGrey00,
    },
    '&.MuiChip-clickable:focus': {
      backgroundColor: ColorPalette.lightGrey00,
    },
  },
  sizeSmall: {
    fontSize: '12px',
  },
  deleteIcon: {
    fill: 'rgba(0,0,0,87)',
  },
  primary: {
    backgroundColor: fdProColors.alert.info,
  },
  success: {
    backgroundColor: fdProColors.alert.success,
  },
  done: {
    backgroundColor: fdProColors.alert.success,
  },
  warning: {
    backgroundColor: fdProColors.alert.warning,
  },
  error: {
    backgroundColor: fdProColors.alert.error,
  },
  action: {
    backgroundColor: fdProColors.alert.info,
  },
  default: {
    backgroundColor: fdProColors.alert.default,
  },
  started: {
    backgroundColor: fdProColors.alert.info,
  },
  squaredChip: {
    borderRadius: '4px',
  },
}));

const FdChip = ({ color, icon, labelType, rounded, ...props }) => {
  const { classes } = useStyles();

  return (
    <Chip
      color="primary"
      classes={{
        root: labelType ? classes.labelTypeRoot : classes.root,
        colorPrimary: classes[color],
        sizeSmall: classes.sizeSmall,
        deleteIcon: classes.deleteIcon,
      }}
      className={rounded ? '' : classes.squaredChip}
      icon={icon()}
      {...props}
    />
  );
};

FdChip.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.func,
  labelType: PropTypes.bool,
  rounded: PropTypes.bool,
};

FdChip.defaultProps = {
  color: 'default',
  icon: () => {},
  labelType: false,
  rounded: true,
};

export default FdChip;
