import React from 'react';
import PropTypes from 'prop-types';
import { Link, useTheme } from '@mui/material';

const FdExternalLink = ({ href, children, noUnderline, ...props }) => {
  const theme = useTheme();
  return (
    <Link
      target="_blank"
      style={{
        color: theme?.palette?.typography?.link || '#1976D2',
        textDecoration: noUnderline ? 'none' : 'underline',
        margin: '0 0.3rem',
      }}
      href={href}
      rel="noreferrer"
      {...props}
    >
      {children}
    </Link>
  );
};

FdExternalLink.defaultProps = {
  noUnderline: false,
};

FdExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noUnderline: PropTypes.bool,
};

export default FdExternalLink;
