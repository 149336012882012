import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useForm,
  FormProvider,
  useFieldArray,
  Controller,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Drawer,
  Card,
  CardHeader,
  IconButton,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FdTextField from '../FdTextField';
import FdAvatar from '../FdAvatar';
import FdTypography from '../FdTypography';
import {
  getAvatarColor,
  getAvatarName,
  setAppMarginRightByIds,
  getDateTimeZoneCustom,
} from '../../shared';
import useStyles from './styles';
import { FdLoadingSpinner } from '../FdLoading';
import Yup from '../../validation-schemas/Common';
import ChatSendButton from './ChatSendButton';

const handleOpenDrawer = (drawerState, parentAppId) => {
  const mainPageIds = ['topnav', parentAppId];
  const marginRightBy = drawerState ? '400px' : '0';
  setTimeout(() => {
    setAppMarginRightByIds(mainPageIds, marginRightBy);
  }, 500);
};

const FdChatDrawer = ({
  openChat,
  onCloseChat,
  chatTitle,
  showAvatarOnTitle,
  chatAuthorName,
  onAddChatMessage,
  chatHistory,
  parentAppId,
  loading,
  avatarOrgLogoOnNewMessage,
  isDirty,
}) => {
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const { classes } = useStyles();

  const validationSchema = Yup.object().shape({
    chatText: Yup.string()
      .max(300, 'Please limit your message to 300 characters or less.')
      .nullable(),
    chatHistory: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        message: Yup.string(),
        createdAt: Yup.string(),
        avatarImage: Yup.mixed(),
        senderName: Yup.string(),
      }),
    ),
  });

  const reactHookFormMethods = useForm({
    defaultValues: {
      chatText: '',
      chatHistory: [],
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, setValue, getValues, control } = reactHookFormMethods;

  const { fields: messages, append: appendMessage } = useFieldArray({
    control,
    name: 'chatHistory',
  });

  const setInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // update chatHistory after loading
  useEffect(() => {
    reset({ chatHistory });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // open drawer on openChat change
  useEffect(() => {
    handleOpenDrawer(openChat, parentAppId);
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView();
      setInputFocus();
    }, 1000);
    // reset drawer on unmount
    return () => {
      handleOpenDrawer(false, parentAppId);
    };
  }, [openChat, parentAppId]);

  // scroll to view on new message
  useEffect(() => {
    if (messagesEndRef?.current) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
    setInputFocus();
  }, [messages.length, loading, openChat]);

  const addChatMessage = () => {
    const dateSubmitted = new Date();
    const message = getValues('chatText');
    // insert into chat history
    appendMessage({
      name: chatAuthorName,
      message,
      createdAt: dateSubmitted,
      avatarImage: avatarOrgLogoOnNewMessage,
      senderName: 'You',
    });
    setValue('chatText', '');
    onAddChatMessage({ message, dateSubmitted });
  };

  return (
    <Box>
      <Drawer
        variant="persistent"
        anchor="right"
        open={openChat}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Card maxWidth="100%" style={{ height: '100%' }}>
          <CardHeader
            action={
              <IconButton onClick={() => onCloseChat(false)} size="large">
                <CloseIcon style={{ fontSize: 28 }} />
              </IconButton>
            }
            title={
              <Box display="flex">
                {showAvatarOnTitle && (
                  <FdAvatar
                    size="small"
                    content={getAvatarName(chatTitle)}
                    color={getAvatarColor(chatTitle)}
                  />
                )}
                <Box ml={1} maxWidth="300px" className={classes.chatHeading}>
                  {chatTitle}
                </Box>
              </Box>
            }
          />
          <CardContent className={classes.cardContent}>
            <FormProvider {...reactHookFormMethods}>
              <form>
                {loading ? (
                  <Box
                    display="flex"
                    height="calc(100vh - 116px)"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FdLoadingSpinner />
                  </Box>
                ) : (
                  <Box className="flex flex-col" height="calc(100vh - 116px)">
                    <Box flexGrow={1} style={{ overflowY: 'auto' }}>
                      {messages
                        .sort(
                          (a, b) =>
                            new Date(a.createdAt).getTime() -
                            new Date(b.createdAt).getTime(),
                        )
                        .map((c) => (
                          <Box
                            display="flex"
                            className={classes.messageDisplayBox}
                          >
                            <Box>
                              <FdAvatar
                                size="small"
                                content={
                                  <Box style={{ textAlign: 'center' }}>
                                    {c.avatarImage ? (
                                      <>{c.avatarImage}</>
                                    ) : (
                                      getAvatarName(c.name)
                                    )}
                                  </Box>
                                }
                                color={
                                  c.avatarImage
                                    ? 'transparent'
                                    : getAvatarColor(c.name)
                                }
                              />
                            </Box>
                            <Box>
                              <Box ml={1} height="33px">
                                <FdTypography variant="caption">
                                  {c?.senderName}
                                </FdTypography>
                              </Box>

                              <Box ml={1} p={2} className={classes.message}>
                                <FdTypography variant="body1">
                                  {c.message}
                                </FdTypography>
                              </Box>
                              <Box
                                ml={1}
                                pt={0.5}
                                className={classes.dateCaption}
                              >
                                <FdTypography variant="caption">
                                  {getDateTimeZoneCustom(
                                    c.createdAt,
                                    'MMM dd, yyyy h:mm a',
                                  )}
                                </FdTypography>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      {messages.length === 0 && (
                        <Box
                          display="flex"
                          height="100%"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box width="192px" textAlign="center">
                            <FdTypography variant="body2" color="secondary">
                              When you send or receive a message, it will
                              populate here.
                            </FdTypography>
                          </Box>
                        </Box>
                      )}
                      <div ref={messagesEndRef} />
                    </Box>
                    <Box className="flex flex-col w-full">
                      <Controller
                        control={control}
                        name="chatText"
                        render={({
                          field: { value, ...rest },
                          fieldState: { error },
                        }) => (
                          <FdTextField
                            id="chatText"
                            label=""
                            value={value}
                            required
                            fullWidth
                            multiline
                            placeholder="Type to send a message"
                            error={error}
                            helperText={error && error.message}
                            data-cy="chat-box"
                            maxRows={3}
                            inputRef={inputRef}
                            {...rest}
                            style={{ flexGrow: 1, marginRight: '1rem' }}
                          />
                        )}
                      />
                      <ChatSendButton
                        addChatMessage={addChatMessage}
                        isDirty={isDirty}
                      />
                    </Box>
                  </Box>
                )}
              </form>
            </FormProvider>
          </CardContent>
        </Card>
      </Drawer>
    </Box>
  );
};

FdChatDrawer.propTypes = {
  openChat: PropTypes.bool.isRequired,
  onCloseChat: PropTypes.func.isRequired,
  chatTitle: PropTypes.string.isRequired,
  showAvatarOnTitle: PropTypes.bool,
  chatAuthorName: PropTypes.string.isRequired,
  onAddChatMessage: PropTypes.func,
  chatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      avatarImage: PropTypes.node,
      senderName: PropTypes.string.isRequired,
    }),
  ),
  parentAppId: PropTypes.string,
  loading: PropTypes.bool,
  avatarOrgLogoOnNewMessage: PropTypes.node,
  isDirty: PropTypes.func,
};

FdChatDrawer.defaultProps = {
  onAddChatMessage: () => null,
  chatHistory: [],
  parentAppId: '',
  showAvatarOnTitle: true,
  loading: false,
  avatarOrgLogoOnNewMessage: undefined,
  isDirty: () => null,
};

export default FdChatDrawer;
