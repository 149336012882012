import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

function FdAnimatedDigit({ digit }) {
  const elementRef = useRef(null);

  useEffect(() => {
    if (elementRef.current) {
      anime({
        targets: elementRef.current,
        innerText: [0, digit],
        easing: 'linear',
        round: 1,
        duration: 2000,
        update: (anim) => {
          if (elementRef.current) {
            elementRef.current.innerText = anim.animations[0].currentValue;
          }
        },
      });
    }
  }, [digit]);

  return <span ref={elementRef} />;
}

FdAnimatedDigit.defaultProps = {
  digit: 0,
};

FdAnimatedDigit.propTypes = {
  digit: PropTypes.number,
};

export default FdAnimatedDigit;
