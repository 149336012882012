/* FdStepper */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, Typography, StepLabel, StepContent, Button } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  root: {
    width: '100%',
    '& [class*="MuiStepLabel-active"]': {
      fontWeight: 600,
    },
  },
  alternativeLabel: {
    marginTop: '0',
    display: 'block',
    textAlign: 'center',
  },
});

const FdStepper = ({
  vertical, steps, errorSteps, completedSteps,
  errorMessage, alternativeLabel, ...props
}) => {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const isStepFailed = (step) => (errorSteps?.indexOf(step) > -1);

  const isStepCompleted = (step) => (completedSteps?.indexOf(step) > -1);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const sharedButtonComponent = (
    <div>
      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
      >
        Back
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
      >
        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
      </Button>
    </div>
  );

  return (
    <>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} orientation={vertical ? 'vertical' : 'horizontal'} alternativeLabel={alternativeLabel} {...props}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepFailed(steps[index])) {
              labelProps.optional = (
                <Typography variant="caption" color="error" className={alternativeLabel ? `${classes.alternativeLabel} error` : ' error'}>
                  {errorMessage}
                </Typography>
              );
            }

            stepProps.completed = isStepCompleted(steps[index]);
            labelProps.error = isStepFailed(steps[index]);

            return (
              !vertical
                ? (
                  <Step key={label + index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
                : (
                  <Step key={label + index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    <StepContent>

                      {sharedButtonComponent}

                    </StepContent>
                  </Step>
                )
            );
          })}
        </Stepper>

        {!vertical
          && (
            <div>
              {activeStep !== steps?.length
                && sharedButtonComponent}
            </div>
          )}
      </div>

    </>
  );
};

FdStepper.propTypes = {
  vertical: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  errorSteps: PropTypes.arrayOf(PropTypes.string),
  completedSteps: PropTypes.arrayOf(PropTypes.string),
  alternativeLabel: PropTypes.bool,
  errorMessage: PropTypes.string,
};

FdStepper.defaultProps = {
  vertical: false,
  errorSteps: undefined,
  completedSteps: undefined,
  alternativeLabel: false,
  errorMessage: '',
};

export default FdStepper;
