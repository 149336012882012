import { makeStyles } from 'tss-react/mui';
import Color from 'color';

const useStyles = makeStyles()((theme, { variant }) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.background.divider}`,
  },
  menuItem: {
    padding: theme.spacing(1, 2),
    minWidth: theme.spacing(18),
    color: theme.palette.typography.primary,
    backgroundColor: theme.palette.background.menu,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  selectedMenuItem: {
    fontWeight: 500,
    backgroundColor: theme.palette.table.selected,
  },
  icon: {
    fill: theme.palette.iconography.primary,
  },
  listItemIcon: {
    minWidth: theme.spacing(4),
  },
  iconButtonRoot: {
    width: theme.spacing(3),
  },
  variantClass: {
    height: variant === 'medium' ? theme.spacing(5) : theme.spacing(6),
  },
  submenu: {
    padding: theme.spacing(0),
    minWidth: '200px',
    backgroundColor: Color(theme.palette.background.default)
      .lighten(3)
      .string(),
    marginTop: '16px',
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
