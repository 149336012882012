import { ColorPalette } from '../shared/colors';
import typography from '../typography/typography';
import { fdProColors } from './fd-pro-colors';

const c = {
  background: '#002F65',
  paper: '#15447A',
  divider: '#27719B',
  primary: '#63A4FF',
  primary_main: '#51B0E3',
  primary_dark: '#1C76CB',
  primary_light: '#9BD5FF',
  secondary: '#E51937',
  secondary_dark: '#B0003A',
  secondary_light: '#FF6090',
  text_primary: '#fff',
  icon_secondary: 'rgba(255, 255, 255, 0.54)',
  icon_placeholder: 'rgba(255, 255, 255, 0.38)',
  text_secondary: 'rgba(255, 255, 255, 0.7)',
  text_placeholder: 'rgba(255, 255, 255, 0.5)',
  text_disabled: 'rgba(255, 255, 255, 0.24)',
  link: '#51B0E3',
  hover: 'rgba(255, 255, 255, 0.05)',
  selected: 'rgba(255, 255, 255, 0.12)',
  input_background: 'rgba(255,255,255,0.05)',
  input_border: '#B8C0D1',
  input_background_hover: 'rgba(255,255,255,0.08)',
  input_border_hover: '#E3E6EC',
  select_border: '#E0E0E0',

  thumb: '#424242',
};

const theme = {};

theme.common = {
  black: '#000',
  white: '#fff',
};
theme.fdProColors = fdProColors;

theme.palette = {
  type: 'dark',
  mode: 'dark',
  contrastThreshold: 3,
  background: {
    default: c.background,
    paper: c.paper,
    border: c.divider,
    divider: c.divider,
    disabled: ColorPalette.grey05,
    thumb: c.thumb,
    menu: c.background,
    sidebar: c.background,
  },
  primary: {
    main: c.primary,
    dark: c.primary_dark,
    light: c.primary_light,
  },
  secondary: {
    main: c.secondary,
    dark: c.secondary_dark,
    light: c.secondary_light,
  },
  iconography: {
    primary: c.text_primary,
    secondary: c.icon_secondary,
    placeholder: c.icon_placeholder,
    disabled: c.icon_placeholder,
  },
  text: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: c.text_placeholder,
    link: c.primary,
    background: c.input_background,
  },
  typography: {
    primary: c.text_primary,
    secondary: c.text_secondary,
    placeholder: c.text_placeholder,
    disabled: ColorPalette.black00,
    link: c.link,
  },
  inputs: {
    background: c.input_background,
    backgroundHover: c.input_background_hover,
    border: c.input_border,
    borderHover: c.input_border_hover,
    selectBorder: c.select_border,
  },
  table: {
    border: '#27719B',
    hover: 'rgba(255, 255, 255, 0.08)',
    selected: 'rgba(229, 25, 55, 0.25);',
  },
  toggleButton: {
    unSelectedColor: ColorPalette.white01,
  },
  action: {
    hover: c.hover,
    selected: c.selected,
  },
  disabled: {
    background: '#0000004D',
    text: '#ffffff',
  },
  listItem: {
    selectedStroke: c.primary,
    selectedBackground: '#131353',
    color: '#EBF2FF',
  },
  progressBar: {
    background: '#131353',
    completed: '#3333FF',
  },
  sidebar: {
    modeSelector: {
      background: '#3333FF',
      color: '#ffffff',
    },
  },
  customTooltip: {
    background: 'rgba(23, 56, 106, 1)',
    color: 'rgba(255, 255, 255, 1)',
  },
  autoComplete: {
    background: 'rgba(110, 132, 255, 1)',
    color: '#000000',
    chipBackground: 'rgba(51, 51, 255, 1)',
    chipColor: 'rgba(255, 255, 255, 1)',
  },
};

theme.typography = typography;
theme.typography = {
  fontFamily: 'Montserrat',
  h1: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '2.5rem',
    letterSpacing: 0,
  },
  h2: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '2rem',
    letterSpacing: 0,
  },
  h3: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '1.5rem',
    letterSpacing: 0,
  },
  h4: {
    fontFamily: 'Raitor',
    fontWeight: 400,
    fontSize: '1.25rem',
    letterSpacing: 0,
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
  },
  body1: {
    fontWeight: 400,
    fontSize: '1rem',
    letterSpacing: 0,
  },
  body2: {
    fontWeight: 400,
    fontSize: '0.8125rem',
    letterSpacing: 0,
  },
  button_sm: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '0.75rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_med: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '0.875rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  button_lg: {
    fontFamily: 'Raitor',
    fontWeight: 600,
    fontSize: '1rem',
    letterSpacing: 0,
    textTransform: 'uppercase',
  },
  captiontext1: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.75rem',
  },
  captiontext2: {
    fontWeight: 400,
    letterSpacing: 0,
    fontSize: '0.625rem',
  },
};

export default theme;
