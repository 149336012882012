import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, FormHelperText } from '@mui/material/';
import { makeStyles } from 'tss-react/mui';
import Autocomplete from '@mui/material/Autocomplete';
import { ColorPalette } from '../../shared/colors';
import FdTypography from '../FdTypography';

const useStyles = makeStyles()((theme, _params, classes) => ({
  formControl: {
    [`& .${classes.autocompleteRoot}`]: {
      [`& .${classes.autoCompleteInputRoot}`]: {
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        paddingRight: 0,
        '& .labelTitle': {
          maxWidth: '95%',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '& .labelCount': {
          paddingLeft: 5,
        },
      },
    },
  },
  autocompleteRoot: {},
  autoCompleteInputRoot: {},
  errorText: {
    color: ColorPalette.red00,
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
  formHelperSpacing: {
    marginLeft: '0',
    marginTop: '0',
  },
  option: {
    padding: 5,
  },
  listItemIconRoot: {
    margin: 0,
    padding: 0,
    minWidth: 0,
  },
  listItemRoot: {
    padding: 0,
    margin: 0,
  },
}));

const FdMUIMultipleAutocomplete = ({
  options,
  helperText,
  error,
  width,
  fullWidth,
  id,
  onChange,
  label,
  disabled,
  renderInput,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Box
      display={fullWidth ? 'flex' : 'inline-flex'}
      flexDirection="column"
      width={fullWidth ? 'auto' : width}
    >
      {label && (
        <Box width="500px">
          <FdTypography variant="subtitle1">{label}</FdTypography>
        </Box>
      )}
      <FormControl
        variant="outlined"
        className={classes.formControl}
        error={error}
        disabled={disabled}
      >
        <Autocomplete
          {...props}
          multiple
          id="tags-outlined"
          options={options}
          filterSelectedOptions
          limitTags={2}
          freeSolo
          style={{ width: 500 }}
          renderInput={renderInput}
          onChange={onChange}
        />
        {!error && helperText && (
          <Box mt={0.5} width="500px">
            <FormHelperText
              classes={{
                root: classes.formHelperSpacing,
              }}
              id={`${id}-helper-text`}
            >
              {helperText}
            </FormHelperText>
          </Box>
        )}
        {error && (
          <FormHelperText className={classes.errorText}>{error}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

FdMUIMultipleAutocomplete.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** To grow fullwidth of parent */
  fullWidth: PropTypes.bool,
  /** Custom width */
  width: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  renderInput: PropTypes.func,
};

FdMUIMultipleAutocomplete.defaultProps = {
  error: false,
  disabled: false,
  helperText: undefined,
  fullWidth: false,
  width: '276px',
  onChange: () => {},
  label: undefined,
  renderInput: undefined,
};

export default FdMUIMultipleAutocomplete;
