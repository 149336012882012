import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FdTooltip from '../FdTooltip';

const FdIconWithTooltip = ({ title, Icon }) => {
  return (
    <FdTooltip title={title}>
      <IconButton
        size="small"
        style={{
          marginLeft: '5px',
        }}
      >
        {Icon ? <Icon /> : <InfoOutlinedIcon />}
      </IconButton>
    </FdTooltip>
  );
};

FdIconWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  Icon: PropTypes.node,
};

FdIconWithTooltip.defaultProps = {
  Icon: undefined,
};

export default FdIconWithTooltip;
