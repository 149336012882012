import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Card, CardContent, Box, Divider } from '@mui/material';

const FdCardList = ({ data, ...props }) => {
  const lastIdx = data.length - 1;
  return (
    <Card
      style={{ marginTop: '1rem', paddingTop: '4px' }}
      data-cy="card-list"
      {...props}
    >
      <CardContent>
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-around"
        >
          {data.map((element, idx) => (
            <Fragment key={shortid.generate()}>
              {element}
              {idx !== lastIdx && <Divider orientation="vertical" flexItem />}
            </Fragment>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

FdCardList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

FdCardList.defaultProps = {
  data: [],
};

export default FdCardList;
