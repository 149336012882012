import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { getUrl } from 'aws-amplify/storage';
import PropTypes from 'prop-types';
import useAsyncEffect from 'use-async-effect';
import { Box } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { makeStyles } from 'tss-react/mui';
import LazyLoad from 'react-lazyload';
import FdProgress from '../FdProgress/FdProgress';
import amplifyConfig from '../../shared/amplifyConfig';

Amplify.configure(amplifyConfig);

const useStyles = makeStyles()((_theme, { size }) => ({
  s3Image: {
    width: size.width,
    height: size.height,
    objectFit: 'contain',
    padding: size.width === '100%' ? 0 : '0.5rem',
  },
  imageContainer: {
    backgroundColor: '#eee',
    width: size.width,
    height: size.height,
  },
}));

const S3Image = ({ image, size, s3ImageUrl, onImageUrlFromS3 }) => {
  const [imageUrl, setImageUrl] = useState(s3ImageUrl);
  const [loading, setLoading] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);
  const { classes } = useStyles({ size });

  const getImageUrlFromS3 = async (key) => {
    try {
      const imageData = await getUrl({
        path: `public/${key}`,
      });
      return imageData.url.toString();
    } catch (error) {
      return error.message;
    }
  };

  useAsyncEffect(
    async (isActive) => {
      try {
        setLoading(true);
        const imageS3Url = await getImageUrlFromS3(image);
        onImageUrlFromS3?.(imageS3Url);
        if (!isActive()) {
          return;
        }
        setImageUrl(imageS3Url);
        setLoading(false);
      } catch (error) {
        if (isActive()) setLoading(false);
      }
    },
    [image],
  );

  if (loading) {
    return (
      <Box
        width="345px"
        height="215px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <FdProgress />
      </Box>
    );
  }

  return loadFailed || !image ? (
    <CropOriginalIcon
      style={{ width: '200px', height: '200px', opacity: '25%' }}
    />
  ) : (
    <LazyLoad placeholder={<Box className={classes.imageContainer} />}>
      {imageUrl && (
        <img
          src={imageUrl}
          alt="org-img"
          crossOrigin="anonymous"
          className={classes.s3Image}
          onError={() => setLoadFailed(true)}
        />
      )}
    </LazyLoad>
  );
};

S3Image.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  s3ImageUrl: PropTypes.string,
  onImageUrlFromS3: PropTypes.func,
};

S3Image.defaultProps = {
  size: {
    width: '370px',
    height: '',
  },
  s3ImageUrl: undefined,
  onImageUrlFromS3: undefined,
};

export default S3Image;
