import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { color, variant, fillIcon }) => ({
  avatar: {
    backgroundColor: color,
    '& svg': {
      width: `20px ${!fillIcon && '!important'}`,
      height: 'auto',
    },
    color: '#fff',
    boxShadow: !fillIcon && `0 0 0 2px ${theme.palette.background.paper}`,
    borderRadius: variant === 'square' && '4px',
  },
  xsmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '12px',
  },
  small: {
    width: '31px',
    height: '31px',
    fontSize: '12px',
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '20px',
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: '24px',
  },
  square: {
    borderRadius: '4px',
  },
}));

const FdAvatar = ({ variant, content, size, fillIcon, color, ...rest }) => {
  const { classes, cx } = useStyles({ color, variant, fillIcon });
  return (
    <Avatar
      variant={variant}
      {...rest}
      className={cx({
        [classes.avatar]: true,
        [classes.xsmall]: size === 'xsmall',
        [classes.small]: size === 'small',
        [classes.large]: size === 'large',
        [classes.square]: variant === 'square',
      })}
    >
      {content}
    </Avatar>
  );
};

FdAvatar.propTypes = {
  variant: PropTypes.oneOf(['circle', 'circular', 'rounded', 'square']),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  color: PropTypes.string,
  fillIcon: PropTypes.bool,
};

FdAvatar.defaultProps = {
  variant: 'circular',
  size: 'medium',
  color: '#F7941D',
  fillIcon: false,
};

export default FdAvatar;
