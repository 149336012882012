import React from 'react';
import { useSnapshot } from 'valtio';
import { Box } from '@mui/material';
import FdTypography from '../FdTypography';
import FdLightLogo from './images/fd-light-logo.png';
import FdDarkLogo from './images/fd-dark-logo.png';
import globalStore from '../../store';

const FooterLogo = () => {
  const { userType } = useSnapshot(globalStore);

  return (
    <Box className="flex items-center justify-center">
      <FdTypography
        variant="captiontext1"
        style={{ fontSize: '8px' }}
        className="group-data-[collapsible=icon]:hidden"
      >
        Powered by
      </FdTypography>
      <img
        src={userType === 'PARTICIPANT' ? FdDarkLogo : FdLightLogo}
        className="h-7 w-auto text-center group-data-[collapsible=icon]:hidden"
        alt="FifthDomain Logo"
      />
    </Box>
  );
};

export default FooterLogo;
