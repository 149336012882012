/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  switchBase: {
    position: 'absolute',
    color: theme.palette.inputs.selectBorder,
  },
  disabledOff: {
    '& [class*="MuiSwitch-track"]': {
      backgroundColor: theme.palette.inputs.selectBorder,
      opacity: '0.38',
    },
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-checked"]': {
      color: theme.palette.inputs.selectBorder,
    },
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-disabled"] + [class*="MuiSwitch-track"]':
      {
        backgroundColor: theme.palette.inputs.selectBorder,
      },
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-disabled"]': {
      color: theme.palette.inputs.selectBorder,
      opacity: '0.38',
    },
  },
  disabledOn: {
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-checked"]': {
      color: theme.palette.secondary.main,
    },
    ' & [class*="MuiSwitch-colorPrimary"][class*="Mui-checked"] + [class*="MuiSwitch-track"]':
      {
        backgroundColor: theme.palette.secondary.main,
        opacity: '0.38',
      },
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-disabled"] + [class*="MuiSwitch-track"]':
      {
        backgroundColor: theme.palette.secondary.main,
        opacity: '0.12',
      },
    '& [class*="MuiSwitch-colorPrimary"][class*="Mui-disabled"]': {
      color: theme.palette.secondary.main,
      opacity: '0.38',
    },
  },
}));

const FdSwitch = ({ disabled, status }) => {
  const { classes } = useStyles();
  const checkedB = status === 'ON';
  const [state, setState] = useState({
    checkedB,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <div>
      <Switch
        className={status === 'ON' ? classes.disabledOn : classes.disabledOff}
        classes={{ switchBase: classes.switchBase }}
        disabled={disabled}
        checked={state.checkedB}
        onChange={handleChange}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </div>
  );
};

FdSwitch.propTypes = {
  disabled: PropTypes.oneOf([true, false]),
  status: PropTypes.oneOf(['ON', 'OFF']),
};

FdSwitch.defaultProps = {
  disabled: false,
  status: 'ON',
};

export default FdSwitch;
