/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useAppTheme } from '../hooks';

const withThemeV5ProviderSubscribe = (Component) => (props) => {
  const theme = useAppTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default withThemeV5ProviderSubscribe;
