import React, { useEffect, useRef, useState } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { LinearProgress, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FdTypography from '../FdTypography';
import FdLoadingSpinner from '../FdLoading/FdLoadingSpinner';

const sizeMap = {
  small: 32,
  medium: 48,
  large: 72,
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.progressBar.background,
  },
  top: {
    color: theme.palette.progressBar.completed,
    animationDuration: '1000ms',
    position: 'absolute',
    left: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
  linearProgress: {
    height: '8px',
    width: '294px',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.progressBar.background,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.progressBar.completed,
  },
}));

function FdProgress({ type, size, caption, ...otherProps }) {
  const { classes } = useStyles();
  const progressRef = useRef(null);
  const [aniValue, setAniValue] = useState(0);

  useEffect(() => {
    anime({
      targets: { value: 0 },
      value: otherProps.value,
      easing: 'easeInOutQuad',
      duration: 2000, // Adjust the duration as needed
      update: (anim) => {
        setAniValue(anim.animations[0].currentValue);
      },
    });
  }, [otherProps.value]);

  if (type === 'linear') {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <LinearProgress
          variant="determinate"
          classes={{
            root: classes.linearProgress,
            bar: classes.bar,
            colorPrimary: classes.colorPrimary,
          }}
          ref={progressRef}
          {...otherProps}
          value={aniValue}
        />
        {caption && (
          <Box pt={1}>
            <FdTypography variant="captiontext2">{caption}</FdTypography>
          </Box>
        )}
      </Box>
    );
  }

  return <FdLoadingSpinner size={sizeMap[size]} />;
}

FdProgress.defaultProps = {
  type: 'circular',
  size: undefined,
  caption: undefined,
  fullscreen: false,
};

FdProgress.propTypes = {
  type: PropTypes.oneOf(['circular', 'linear']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  caption: PropTypes.string,
  fullscreen: PropTypes.bool,
};

export default FdProgress;
