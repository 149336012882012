import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import PropTypes from 'prop-types';

const TopNav = ({ leftComponents, rightComponents }) => {
  return (
    <Box>
      <AppBar>
        <Toolbar
          className="flex justify-between"
          sx={{ backgroundColor: (theme) => theme.palette.background.sidebar }}
        >
          <Box className="flex items-center justify-center">
            {leftComponents?.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Box>
          <Box className="flex items-center justify-center">
            {rightComponents?.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

TopNav.propTypes = {
  /** set of elements to be aligned on the left side of the navbar */
  leftComponents: PropTypes.arrayOf(PropTypes.any),
  /** set of elements to be aligned on the right side of the navbar */
  rightComponents: PropTypes.arrayOf(PropTypes.any),
};

TopNav.defaultProps = {
  leftComponents: [],
  rightComponents: [],
};

export default TopNav;
