import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles()((theme, { error, blockquote }, classes) => ({
  formLabel: {
    color: theme.palette.typography.primary,
    ...theme.typography.subtitle1,
    [`&.${classes.focused}`]: {
      color: theme.palette.typography.primary,
    },
    marginLeft: '-8px',
  },
  focused: {},
  errored: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeft:
      error && blockquote ? `4px solid ${ColorPalette.red00}` : 'none',
    paddingLeft: error && blockquote ? theme.spacing(2) : 0,
  },
  radioRoot: {
    marginLeft: 0,
    color:
      error && blockquote
        ? ColorPalette.red00
        : theme.palette.inputs.selectBorder,
    [`&.${classes.checked}`]: {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  errorLabel: {
    ...theme.typography.captiontext1,
    margin: 0,
    color:
      error && blockquote
        ? ColorPalette.red01
        : theme.palette.inputs.selectBorder,
  },
  errorText: {
    ...theme.typography.captiontext1,
    margin: 0,
    color: ColorPalette.red01,
  },
  verticallyCentreChildren: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const FdRadioGroup = ({
  id,
  label,
  onChange,
  defaultValue,
  error,
  optionLabel,
  options,
  required,
  disabled,
  placement,
  blockquote,
}) => {
  const { classes, cx } = useStyles({ error, disabled, blockquote });
  const [value, setValue] = useState(defaultValue);

  const getOptions = (values) => {
    const res = values?.map((option, idx) => {
      const optionValue =
        typeof option === 'object' ? option?.[optionLabel] : option;
      const optionData = typeof option === 'object' ? option : {};
      if (disabled?.some((d) => idx === d)) {
        return {
          value: optionValue,
          disabled: true,
          optionData,
        };
      }
      return {
        value: optionValue,
        disabled: false,
        optionData,
      };
    });

    return res;
  };

  const handleRadioChange = (event) => {
    const selectedOption = getOptions(options).find(
      (_value) => _value.value === event.target.value,
    );
    const { value: optionValue, optionData } = selectedOption || {};

    onChange({
      id,
      value: optionValue,
      optionData,
    });

    setValue(event.target.value);
  };

  return (
    <FormControl
      classes={{
        root: error && classes.errored,
      }}
    >
      <FormLabel
        classes={{
          root: classes.formLabel,
          focused: classes.focused,
        }}
        required={required}
      >
        {label}
      </FormLabel>
      <RadioGroup
        id={id}
        aria-label={label}
        name={id}
        value={value || ''}
        onChange={handleRadioChange}
        row={placement === 'horizontal'}
      >
        {getOptions(options).map((_value) => (
          <FormControlLabel
            value={_value.value}
            label={_value.value}
            disabled={_value.disabled}
            control={
              <Radio
                classes={{
                  root: classes.radioRoot,
                  checked: classes.checked,
                }}
              />
            }
            className={cx({ [classes.errorLabel]: error })}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText className={classes.errorText}>{error}</FormHelperText>
      )}
    </FormControl>
  );
};

FdRadioGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.arrayOf(PropTypes.number),
  placement: PropTypes.oneOf(['horizontal', 'vertical']),
  optionLabel: PropTypes.string,
  blockquote: PropTypes.bool,
};

FdRadioGroup.defaultProps = {
  required: false,
  label: '',
  defaultValue: undefined,
  error: null,
  disabled: [],
  placement: 'vertical',
  optionLabel: '',
  blockquote: true,
};

export default FdRadioGroup;
