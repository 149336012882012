import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { AuthContext } from '../contexts';

const useAuthSession = ({ onError } = {}) => {
  const { user, setUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const userData = await getCurrentUser();
        const session = await fetchAuthSession();
        const userSessionData = {
          ...userData,
          signInUserSession: { accessToken: session?.tokens?.accessToken },
          email: session?.tokens?.idToken?.payload?.email,
        };
        setUser(userSessionData);
      } catch (err) {
        console.log('error: ', err);
        if (onError) {
          onError(err);
        } else {
          history.push('/');
        }
      }
    };

    if (!user) {
      fetchUserSession();
    }
  }, [history, setUser, onError]);

  return { user, setUser };
};

export default useAuthSession;
