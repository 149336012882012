import React from 'react';
import PropTypes from 'prop-types';
import { Button, alpha } from '@mui/material';

const getRootStyle = (theme, size) => ({
  padding:
    size === 'small' ? '0 12px' : size === 'large' ? '8px 16px' : '8px 16px',
  fontSize: size === 'small' ? '12px' : size === 'large' ? '16px' : '14px',
  minHeight: size === 'small' ? '28px' : size === 'large' ? '40px' : '32px',
  height: 'fit-content',
  fontWeight: theme.palette.mode === 'dark' ? 400 : 600,
  lineHeight: 'normal',
  fontFamily: theme.palette.mode === 'dark' ? 'Raitor' : 'Montserrat',
  letterSpacing: theme.palette.mode === 'dark' ? '0.1em' : 'normal',
});

const FdButton = ({
  variant,
  size,
  disabled,
  classes: overrideClasses,
  startIcon,
  endIcon,
  ...props
}) => {
  return (
    <Button
      {...props}
      variant={
        variant === 'primary'
          ? 'contained'
          : variant === 'secondary'
          ? 'outlined'
          : 'text'
      }
      color="primary"
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      disableTouchRipple
      disableFocusRipple
      disableElevation
      data-cy={props['data-cy'] || props.id}
      sx={(theme) => ({
        ...getRootStyle(theme, size),
        color:
          variant === 'primary'
            ? theme.palette.mode === 'dark'
              ? '#000'
              : theme.palette.primary.contrastText
            : theme.palette.primary.main,
        border:
          variant === 'secondary'
            ? `1px solid ${theme.palette.primary.main}`
            : 'none',
        backgroundColor:
          variant === 'primary' ? theme.palette.primary.main : 'transparent',
        '&:hover': {
          color:
            theme.palette.mode === 'dark'
              ? '#fff'
              : variant === 'primary'
              ? theme.palette.primary.contrastText
              : theme.palette.primary.main,
          backgroundColor:
            variant === 'primary'
              ? theme.palette.primary.dark
              : alpha(theme.palette.primary.main, 0.1),
          border:
            variant === 'secondary'
              ? `1px solid ${theme.palette.primary.main}`
              : 'none',
        },
        '&:active': {
          backgroundColor:
            variant === 'primary'
              ? theme.palette.primary.light
              : alpha(theme.palette.primary.main, 0.3),
        },
        '&:disabled': {
          color: theme.palette.background.disabled,
          backgroundColor:
            variant === 'primary'
              ? theme.palette.background.disabled
              : 'transparent',
          borderColor:
            variant === 'secondary'
              ? theme.palette.background.disabled
              : 'none',
        },
        ...overrideClasses,
      })}
    />
  );
};

FdButton.propTypes = {
  /** These are the types of buttons */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  /** In 3 sizes */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /** Disabled */
  disabled: PropTypes.bool,
  /** Classes can be passed to the button to override Mui styles */
  classes: PropTypes.shape({}),
  /** Icon on left of button (optional) */
  startIcon: PropTypes.node,
  /** Icon on right of button (optional) */
  endIcon: PropTypes.node,
  'data-cy': PropTypes.string,
  id: PropTypes.string,
};

FdButton.defaultProps = {
  variant: 'primary',
  size: 'medium',
  disabled: false,
  classes: {},
  startIcon: undefined,
  endIcon: undefined,
  'data-cy': undefined,
  id: undefined,
};

export default FdButton;
