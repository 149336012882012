import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider, Typography } from '@mui/material';
import Chip from '../FdChip';

function FilterBar({ apiRef }) {
  const columns = _.chain(apiRef.current.getAllColumns())
    .map(({ field, headerName }) => [field, headerName])
    .fromPairs()
    .value();

  const currentFilters = _.chain(apiRef)
    .get(['current', 'state', 'filter', 'filterModel', 'items'], [])
    .filter((x) => !_.isUndefined(x.value))
    .value();

  const formatFilter = _.chain(Object.keys(columns))
    .map((key) => {
      const matchingFilters = currentFilters.filter(
        (x) => x.columnField === key,
      );
      if (_.isEmpty(matchingFilters)) {
        return [];
      }
      return [key, _.map(matchingFilters, 'value').join(',')];
    })
    .filter((x) => !_.isEmpty(x))
    .fromPairs()
    .value();
  const filterChips = Object.keys(formatFilter) || [];

  return (
    <>
      <Divider />
      <Box width="100%" height="20px" m={2} display="flex" alignItems="center">
        <Typography color="textSecondary">Filters: </Typography>
        <Box m={2} />
        {filterChips.map((key) => (
          <>
            <Chip
              label={`${columns[key]}: ${formatFilter[key]}`}
              onDelete={() => {
                currentFilters
                  .filter((x) => key === x.columnField)
                  .forEach((item) => apiRef.current.deleteFilterItem(item));
              }}
            />
            <Box m={0.5} />
          </>
        ))}
      </Box>
      <Divider />
    </>
  );
}

FilterBar.propTypes = {
  apiRef: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
};

export default FilterBar;
