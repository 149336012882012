import React, { useState } from 'react';
import { Box } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PropTypes from 'prop-types';
import FdTypography from '../FdTypography';

const CommentReaction = ({
  icon,
  count,
  currentUserLiked,
  useReaction,
  userId,
  messageId,
  reactionsViewOnly,
}) => {
  const [reactionCount, setReactionCount] = useState(count);
  const [submitted, setSubmitted] = useState(currentUserLiked);
  const Icon = icon || ThumbUpIcon;

  const colors = {
    backgroundColor: submitted ? 'rgba(76, 90, 255, 1)' : 'transparent',
    borderColor: 'rgba(76, 90, 255, 1)',
    iconOrTextColor: submitted
      ? '#fff'
      : reactionsViewOnly
      ? 'rgba(0, 0, 0, 0.87)'
      : 'rgba(76, 90, 255, 1)',
    hoverBackgroundColor: 'rgba(36, 32, 226, 1)',
  };

  const saveReaction = useReaction();

  const handleClick = () => {
    if (reactionsViewOnly) return;
    setReactionCount((prevCount) => {
      const newCount = submitted ? prevCount - 1 : prevCount + 1;
      setSubmitted((prevSubmitted) => !prevSubmitted);
      const likeOrDislike = !submitted ? 'LIKE' : 'NONE';
      saveReaction({ userId, messageId, likeOrDislike });
      return newCount;
    });
  };

  return (
    <span
      style={{
        '&:hover': {
          backgroundColor: colors.hoverBackgroundColor,
          border: `1px solid ${colors.hoverBackgroundColor}`,
        },
      }}
    >
      <Box
        className={`rounded-xl px-3 gap-2 inline-flex justify-center items-center ${
          reactionsViewOnly ? 'cursor-default' : 'cursor-pointer'
        }`}
        mt={1}
        style={{
          backgroundColor: colors.backgroundColor,
          border: reactionsViewOnly
            ? 'none'
            : `1px solid ${colors.borderColor}`,
          '::hover': {
            backgroundColor: colors.hoverBackgroundColor,
            border: `1px solid ${colors.hoverBackgroundColor}`,
          },
        }}
        onClick={handleClick}
        height={25}
      >
        <Icon
          style={{
            height: '16px',
            width: '16px',
            color: colors.iconOrTextColor,
          }}
        />
        <FdTypography
          variant="caption"
          style={{ color: colors.iconOrTextColor }}
        >
          {reactionCount}
        </FdTypography>
      </Box>
    </span>
  );
};

CommentReaction.defaultProps = {
  count: 0,
  icon: undefined,
  reactionsViewOnly: false,
};

CommentReaction.propTypes = {
  icon: PropTypes.node,
  count: PropTypes.number,
  reactionsViewOnly: PropTypes.bool,
  currentUserLiked: PropTypes.bool.isRequired,
  useReaction: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default CommentReaction;
