import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import FdTypography from '../FdTypography';

const useStyles = makeStyles()((theme) => ({
  cardRoot: {
    marginBottom: '0.5rem',
    border: `1px solid ${theme.palette.table.border}`,
    borderRadius: '8px',
  },
  cardSubHeading: {
    fontSize: '1rem',
    color: theme.palette.typography.secondary,
  },
  cardContentRoot: {
    padding: '1.5rem',
  },
}));

const FdCard = ({
  heading,
  headingVariant,
  subHeading,
  preHeader,
  optional,
  media,
  summary,
  children,
  ...props
}) => {
  const { classes } = useStyles();
  return (
    <Card classes={{ root: classes.cardRoot }} {...props}>
      {media && <Box width="100%">{media}</Box>}
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Box display="flex" justifyContent="space-between">
          {heading && (
            <Box width="100%">
              <Box mb={2}>
                {preHeader}
                {heading && (
                  <FdTypography variant={headingVariant}>
                    {heading}
                    {optional && (
                      <span className={classes.cardSubHeading}>
                        &nbsp;(optional)
                      </span>
                    )}
                  </FdTypography>
                )}
              </Box>
              {subHeading && (
                <FdTypography variant="body1" color="secondary">
                  {subHeading}
                </FdTypography>
              )}
            </Box>
          )}
          {summary && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              {summary}
            </Box>
          )}
        </Box>
        <Box mt={heading ? 1 : 0}>{children}</Box>
      </CardContent>
    </Card>
  );
};

FdCard.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  media: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subHeading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  preHeader: PropTypes.node,
  optional: PropTypes.bool,
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headingVariant: PropTypes.string,
};

FdCard.defaultProps = {
  heading: '',
  headingVariant: 'h4',
  media: null,
  subHeading: '',
  preHeader: null,
  optional: false,
  summary: undefined,
};

export default FdCard;
