import React, { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  Box,
  darken,
  IconButton,
  InputAdornment,
  lighten,
  TextField,
  Tooltip,
} from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import EmojiPickerPopper from './EmojiPickerPopper';
import MentionsPopper from './MentionsPopper';
import FdTooltip from '../FdTooltip';
import FdTypography from '../FdTypography';
import { getMentions, replaceAtSymbolInSentence } from './utils';
import ImagePreview from './ImagePreview';

const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

function fileSizeValidator(file) {
  if (file.size > maxSizeInBytes) {
    return {
      code: 'too-large',
    };
  }
  return null;
}

const AddComment = ({
  onAddComment,
  userList,
  shortMode,
  placeholder,
  captionText,
  warningToastMessage,
  messageCharLimit,
}) => {
  const [chatMessage, setChatMessage] = useState('');
  const [imagePreview, setImagePreview] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [showMentionsPicker, setShowMentionsPicker] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const [mentionText, setMentionText] = useState('');
  const [textFieldCursorPosition, setTextFieldCursorPosition] = useState(null);
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop: (files) => {
      const file = files[0];
      if (files.length > 1) {
        warningToastMessage('Only one image file is accepted for each message');
      }
      if (file) {
        setImageFile(files[0]);
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
      }
    },
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/gif': ['.gif'],
    },
    multiple: false,
    validator: fileSizeValidator,
    onDropRejected: (files) => {
      if (files?.length > 0) {
        warningToastMessage('Only image files smaller than 5MB are accepted.');
      }
    },
  });

  const textFieldRef = useRef(null);
  const chatMessageLimitReached = chatMessage.trim().length > messageCharLimit;

  const onReactionClick = (emoji) => {
    if (textFieldRef.current) {
      const currentValue = textFieldRef.current.value;
      const newValue =
        currentValue.substring(0, textFieldCursorPosition) +
        emoji +
        currentValue.substring(textFieldCursorPosition);
      textFieldRef.current.value = newValue;
      setChatMessage(newValue);
    }
  };

  // const handlePaste = (event) => {
  //   const clipboardData = event.clipboardData || window.clipboardData;
  //   // Get image from clipboard
  //   if (clipboardData && clipboardData.items) {
  //     for (let i = 0; i < clipboardData.items.length; i += 1) {
  //       const item = clipboardData.items[i];
  //       if (item.type.indexOf('image') !== -1) {
  //         const imageBlob = item.getAsFile();
  //         setImageFile(imageBlob);
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //           setImagePreview(e.target.result);
  //         };
  //         reader.readAsDataURL(imageBlob);
  //         break;
  //       }
  //     }
  //   }
  // };

  // Drag and drop of image
  // const handleDrop = (event) => {
  //   event.preventDefault();
  //   const droppedFile = event.dataTransfer.files[0];
  //   setImageFile(droppedFile);
  //   if (droppedFile.type.startsWith('image/')) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setImagePreview(e.target.result);
  //     };
  //     reader.readAsDataURL(droppedFile);
  //   }
  // };

  // Handle key up event with debounced function
  const handleInputChange = (event) => {
    const text = event.target.value;
    setChatMessage(text);
    const cursorPosition = textFieldRef.current.selectionStart;
    if (cursorPosition === null) return;
    setTextFieldCursorPosition(cursorPosition);
    const { mentions: newMentions } = getMentions(text, cursorPosition);
    setMentionText(newMentions[0]);

    if (newMentions[0]) {
      // Filter list based on searchText (Sample data used here)
      const filteredItems = userList?.filter((item) =>
        item?.alias.toLowerCase().includes(newMentions[0]?.toLowerCase()),
      );
      setFilteredUsers(filteredItems);
    }
    if (newMentions[0] === '') {
      setFilteredUsers(userList);
    }
    setShowMentionsPicker(newMentions.length > 0 ? textFieldRef.current : null);
  };
  const chatMessageCharLength = chatMessage?.trim()?.length || 0;
  const emptyChatMessage = chatMessageCharLength === 0;

  const mentionReset = () => {
    setFilteredUsers(userList);
    setShowMentionsPicker(null);
    textFieldRef.current.focus();
  };

  const onMentionSelection = (u) => {
    setChatMessage((msg) => {
      const text = replaceAtSymbolInSentence(
        msg,
        `@[${u?.alias}] `,
        `@${mentionText}`,
      );
      return text;
    });
    setFilteredUsers(userList);
    setShowMentionsPicker(null);
    textFieldRef.current.focus();
  };
  const getBgColor = (theme) =>
    theme?.palette?.type === 'dark'
      ? '#111010'
      : theme.palette.inputs.background;

  const handleSubmit = () => {
    if (chatMessageLimitReached) {
      return;
    }
    onAddComment(chatMessage?.trim(), imagePreview, imageFile);
    setChatMessage('');
    setImagePreview(undefined);
    setImageFile(undefined);
  };

  const handleKeyDown = (event) => {
    if (chatMessage.trim() === '') return;
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        // `metaKey` is `Command` on macOS
        // Ctrl + Enter or Command + Enter or Shift + Enter: Add a new line
        event.preventDefault();
        setChatMessage((prevText) => `${prevText}\n`);
      } else {
        // Enter only: Submit form
        event.preventDefault();
        handleSubmit();
      }
    }
  };

  return (
    <>
      <Box
        className="flex flex-col items-center w-full border rounded-2xl"
        sx={(theme) => ({
          backgroundColor: getBgColor(theme),
          borderColor:
            (theme?.palette?.type === 'dark'
              ? theme?.palette?.primary?.main
              : theme?.palette?.inputs?.border) || '#ffffff',
        })}
      >
        {imagePreview && (
          <Box className="flex mt-3 bg-transparent w-full ml-20 gap-x-3">
            <ImagePreview
              imageSrc={imagePreview}
              onClose={() => {
                setImagePreview(null);
                setImageFile(undefined);
              }}
              alt="Preview"
            />
          </Box>
        )}
        <TextField
          variant="outlined"
          placeholder={placeholder}
          inputRef={textFieldRef}
          id="comment-field"
          multiline
          maxRows={6}
          value={chatMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          // onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          // onPaste={handlePaste}
          sx={(theme) => ({
            width: '100%',
            '& .MuiInputBase-input': {
              fontSize: '13px',
            },
            '& .MuiOutlinedInput-root': {
              display: 'flex',
              alignItems: 'flex-end',
              borderRadius: '16px',
              '& fieldset': {
                border: 'none',
              },
              padding: '0.9rem 0.6rem',
              background: getBgColor(theme),
            },
            '& .MuiInputAdornment-root': {
              alignSelf: 'flex-end',
              paddingBottom: '0.7rem',
            },
          })}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" {...getRootProps()}>
                <input {...getInputProps()} />
                <FdTooltip title="You can upload a single image in each message, limited to PNG, JPG, JPEG or GIF format, with a maximum file size of 5 MB.">
                  <IconButton
                    component="label"
                    sx={{ padding: '0' }}
                    onClick={open}
                  >
                    <InsertPhotoOutlinedIcon
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                        width: '28px',
                        height: '28px',
                      })}
                    />
                  </IconButton>
                </FdTooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  placement="top"
                  title={
                    chatMessageLimitReached &&
                    `Message is too long. Please shorten to ${messageCharLimit} characters or less.`
                  }
                >
                  <span>
                    <IconButton
                      onClick={handleSubmit}
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary.main,
                        color: '#000',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        '&:hover': {
                          backgroundColor: lighten(
                            theme.palette.primary.main,
                            0.2,
                          ),
                        },
                        '&:disabled': {
                          backgroundColor: darken(
                            theme.palette.primary.main,
                            0.5,
                          ),
                          color: '#000',
                        },
                      })}
                      disabled={emptyChatMessage || chatMessageLimitReached}
                    >
                      <ArrowUpwardOutlinedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          className="w-full rounded-2xl"
        />
      </Box>
      <Box className="flex justify-center w-full">
        {!chatMessageLimitReached && (
          <Box className="self-start">
            <EmojiPickerPopper
              onEmojiSelection={onReactionClick}
              shortMode={shortMode}
            />
          </Box>
        )}
        {captionText && (
          <Box className="flex-1 text-center mt-2">
            <FdTypography variant="captiontext2" color="secondary">
              {captionText}
            </FdTypography>
          </Box>
        )}
        {userList?.length > 0 && (
          <MentionsPopper
            onSelection={onMentionSelection}
            onClickAway={mentionReset}
            filteredUsers={filteredUsers}
            open={Boolean(showMentionsPicker)}
            anchorEl={showMentionsPicker}
          />
        )}
      </Box>
    </>
  );
};

AddComment.propTypes = {
  onAddComment: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.string).isRequired,
  shortMode: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
  captionText: PropTypes.string.isRequired,
  warningToastMessage: PropTypes.func.isRequired,
  messageCharLimit: PropTypes.number.isRequired,
};

export default AddComment;
