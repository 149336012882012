import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { format } from 'date-fns';
import { Box, Divider, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import globalStore from '../../store';
import FdModal from '../FdModal';
import FdTypography from '../FdTypography';
import Avatar from './Avatar';
import CommentReaction from './CommentReaction';
import {
  HYPERLINK_REGEX,
  getAllHyperlinks,
  getAllMentions,
  parseEmojis,
  removeGuardingBraces,
  scrollToAnchor,
} from './utils';
import MessageToolbar from './MessageToolbar';
import EditComment from './EditComment';
import FdExternalLink from '../FdExternalLink/FdExternalLink';
import TeamAvatar from './TeamAvatar';

const getRole = (role, noRoles) => {
  const EVENT_ORGANISER = 'Event Organiser';
  if (noRoles) {
    return EVENT_ORGANISER;
  }
  if (['ADMIN', 'MANAGER'].includes(role)) {
    return EVENT_ORGANISER;
  }
  return '';
};

const MessageHeader = ({
  userName,
  role,
  isDarkMode,
  noRoles,
  team,
  isSizeSmall,
}) => {
  const roleDisplay = getRole(role, noRoles);

  return (
    <Box className="flex items-center gap-x-2">
      <Avatar name={userName} sx={{ width: 28, height: 28 }} />
      <Box
        className={`flex ${isSizeSmall ? 'flex-col' : 'items-center'} gap-x-2`}
      >
        <FdTypography variant="subtitle2">{userName}</FdTypography>
        {!isSizeSmall && roleDisplay && (
          <Box
            className="flex items-center h-4 rounded px-1"
            style={{
              backgroundColor: isDarkMode
                ? 'rgb(32, 34, 143)'
                : 'rgba(203, 220, 236, 1)',
              color: isDarkMode ? '#fff' : '#000',
              fontWeight: 500,
            }}
          >
            <FdTypography variant="captiontext2">{roleDisplay}</FdTypography>
          </Box>
        )}
        {!isSizeSmall && team && (
          <Box
            className="flex items-center gap-x-1 rounded px-1 py-1"
            style={{
              backgroundColor: isDarkMode
                ? 'rgb(32, 34, 143)'
                : 'rgb(236,239,241)',
              color: isDarkMode ? '#fff' : '#000',
              fontWeight: 500,
            }}
          >
            <TeamAvatar team={team} size="small" />
            <FdTypography variant="captiontext2">{team?.name}</FdTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

MessageHeader.defaultProps = {
  team: undefined,
};

MessageHeader.propTypes = {
  userName: PropTypes.string.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  noRoles: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  team: PropTypes.shape({ name: PropTypes.string }),
  isSizeSmall: PropTypes.bool.isRequired,
};

const MessageWrapper = ({ isDarkMode, children, isCurrentUser }) => (
  <Box
    className="mt-1 p-4 whitespace-pre-line rounded-xl"
    sx={(theme) => ({
      // eslint-disable-next-line react/prop-types
      border: `1px solid ${
        isDarkMode ? '#27272A' : theme?.palette?.table?.border
      }`,
      backgroundColor: isDarkMode
        ? isCurrentUser
          ? '#363639'
          : '#0E0E11'
        : 'rgba(247, 247, 247, 1)',
      maxWidth: '640px',
    })}
    position="relative"
  >
    {children}
  </Box>
);

MessageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({}).isRequired,
  isDarkMode: PropTypes.bool.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

const ChatMessage = ({
  message,
  index,
  setRowHeight,
  lastIndex,
  onDelete,
  onDeleteCancel,
  userList,
  updateMessage,
  onUpdateMessage,
  viewOnly,
  noRoles,
  hideReactions,
  currentRole,
  adminView,
  useReaction,
  reactionsViewOnly,
  size,
  shortMode,
  messageCharLimit,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const rowRef = useRef({});
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const { userName, dateTime, comment, image, role, team } = message || {};
  const dateTimeFormatted = format(new Date(dateTime), 'dd MMM yyyy, h:mm a');
  const isDarkMode = theme?.palette?.type === 'dark';
  const isSizeSmall = size === 'small';

  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.getBoundingClientRect().height);
    }
    return () => setIsHovered(false);
    // eslint-disable-next-line
  }, [rowRef]);

  const commentWithoutGuardingBraces = removeGuardingBraces(comment);
  const commentFormatted = parseEmojis(commentWithoutGuardingBraces);
  const isLastIndex = index === lastIndex;
  const allMentions = getAllMentions(comment);
  const allHyperlinks = getAllHyperlinks(comment);

  const renderHighlightTag = (text) => {
    const textContent = text.children;
    const urlRegex = HYPERLINK_REGEX;
    // if hyperlink then send hyperlink content
    if (urlRegex.test(textContent)) {
      return <FdExternalLink href={textContent}>{textContent}</FdExternalLink>;
    }
    // highlight mention
    return (
      <span className="bg-blue-500 text-white rounded-lg h-4 px-2">
        {textContent}
      </span>
    );
  };
  const isCurrentUser = globalSnap?.userAlias === userName;

  return (
    <Box
      ref={rowRef}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      className={`flex flex-col ${isCurrentUser ? 'items-end' : 'items-start'}`}
    >
      {!isCurrentUser && (
        <MessageHeader
          userName={userName}
          role={role}
          isDarkMode={isDarkMode}
          noRoles={noRoles}
          team={team}
          isSizeSmall={isSizeSmall}
        />
      )}
      <Box ml="40px" m={0.5} mb={2}>
        {comment && (
          <Box>
            {editMode && (
              <Box mb={1}>
                <EditComment
                  userList={userList}
                  currentMessage={message}
                  setEditMode={setEditMode}
                  onSave={(msg, _imagePreview, _imageFile) => {
                    updateMessage(index, {
                      ...message,
                      comment: msg,
                      image: _imagePreview,
                      role: currentRole,
                      updatedAt: new Date(),
                    });
                    onUpdateMessage({
                      message: msg,
                      image: _imageFile,
                      messageId: message?.messageId,
                      keyId: message?.keyId,
                    });
                    setEditMode(false);
                  }}
                  onCancel={() => {
                    // this is to refresh the list back to normal
                    updateMessage(index, {
                      ...message,
                    });
                    setEditMode(false);
                  }}
                  shortMode={shortMode}
                  messageCharLimit={messageCharLimit}
                />
              </Box>
            )}
            {!editMode && (
              <MessageWrapper
                isDarkMode={isDarkMode}
                isCurrentUser={isCurrentUser}
              >
                <Box
                  className={`flex items-center gap-x-2 overflow-x-hidden ${
                    isCurrentUser ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <FdTypography
                    variant="body2"
                    color={isCurrentUser ? 'secondary' : 'primary'}
                  >
                    <Highlighter
                      searchWords={[...allMentions, ...allHyperlinks]}
                      textToHighlight={commentFormatted}
                      highlightTag={renderHighlightTag}
                      autoEscape
                    />
                  </FdTypography>
                  {message?.updatedAt &&
                    new Date(message?.dateTime).toISOString() !==
                      new Date(
                        message?.updatedAt || message?.dateTime,
                      ).toISOString() && (
                      <FdTypography variant="captiontext1" color="#6E84FF">
                        (edited)
                      </FdTypography>
                    )}
                </Box>
                {image && (
                  <Box
                    my={1}
                    onClick={() => setShowImageViewer(true)}
                    className="inline-block cursor-pointer"
                  >
                    <img
                      src={image}
                      alt="Preview"
                      style={{
                        width: 'auto',
                        height: isSizeSmall ? '200px' : '300px',
                        borderRadius: '5px',
                      }}
                    />
                  </Box>
                )}
              </MessageWrapper>
            )}
            <Box
              className={`my-1 ${isCurrentUser ? 'text-right' : 'text-left'}`}
            >
              <FdTypography variant="captiontext1" color="secondary">
                {dateTimeFormatted}
              </FdTypography>
            </Box>
            <Box
              className={`flex ${
                hideReactions ? 'justify-end' : 'justify-between'
              }`}
            >
              {!hideReactions && (
                <CommentReaction
                  count={message?.reactionCount}
                  currentUserLiked={message?.currentUserLiked}
                  useReaction={useReaction}
                  userId={globalSnap.userId}
                  messageId={message?.messageId}
                  reactionsViewOnly={reactionsViewOnly}
                />
              )}
              {isHovered && !editMode && !viewOnly && (
                <MessageToolbar
                  onDelete={onDelete}
                  onDeleteCancel={onDeleteCancel}
                  setEditMode={(type) => {
                    setEditMode(type);
                    if (type) {
                      setRowHeight(index, 250);
                      if (isLastIndex) {
                        scrollToAnchor('last-msg-anchor');
                      }
                    }
                  }}
                  deleteModalContent={
                    <Box mt={3}>
                      <Divider className="my-2" />
                      <Box my={2}>
                        <MessageHeader
                          userName={userName}
                          dateTime={dateTimeFormatted}
                          currentUserName={globalSnap?.userName}
                          isDarkMode={isDarkMode}
                        />
                      </Box>
                      <MessageWrapper isDarkMode={isDarkMode} theme={theme}>
                        <FdTypography variant="body2">{comment}</FdTypography>
                      </MessageWrapper>
                    </Box>
                  }
                  adminView={adminView}
                  messageOwner={globalSnap?.userId === message?.userId}
                />
              )}
            </Box>
          </Box>
        )}
        <Box id={isLastIndex ? 'last-msg-anchor' : message?.id} />
      </Box>
      <FdModal
        size="xl"
        title={
          <MessageHeader
            userName={userName}
            dateTime={dateTimeFormatted}
            currentUserName={globalSnap?.userName}
          />
        }
        open={showImageViewer}
        onDismiss={() => setShowImageViewer(false)}
        showClose
        data-cy="image-viewer"
      >
        {image && (
          <Box className="flex items-center justify-center m-1">
            <img
              src={image}
              alt="Preview"
              style={{
                width: 'auto',
                height: 'auto',
                borderRadius: '5px',
              }}
            />
          </Box>
        )}
      </FdModal>
    </Box>
  );
};

ChatMessage.defaultProps = {
  userList: [],
  currentRole: '',
  adminView: false,
  reactionsViewOnly: false,
};

ChatMessage.propTypes = {
  message: PropTypes.shape({
    reactionCount: PropTypes.number,
    id: PropTypes.string,
    messageId: PropTypes.string,
    userId: PropTypes.string,
    currentUserLiked: PropTypes.bool,
    dateTime: PropTypes.string,
    updatedAt: PropTypes.string,
    keyId: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  setRowHeight: PropTypes.func.isRequired,
  lastIndex: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteCancel: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.string),
  updateMessage: PropTypes.func.isRequired,
  onUpdateMessage: PropTypes.func.isRequired,
  useReaction: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  noRoles: PropTypes.bool.isRequired,
  hideReactions: PropTypes.bool.isRequired,
  currentRole: PropTypes.string,
  adminView: PropTypes.bool,
  reactionsViewOnly: PropTypes.bool,
  size: PropTypes.string.isRequired,
  shortMode: PropTypes.bool.isRequired,
  messageCharLimit: PropTypes.number.isRequired,
};

export default ChatMessage;
