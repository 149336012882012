import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Box,
  FormHelperText,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormLabel,
} from '@mui/material/';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';
import FdTypography from '../FdTypography';
import { isObjectArray } from '../../shared/objectUtils';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    '& [class*="Mui-focused"]': {
      color: theme.palette.typography.primary,
    },
    '& [class*="MuiFormControlLabel-root"]': {
      padding: '5px 0',
      alignItems: 'center',
      verticalAlign: 'text-bottom',
      display: 'flex',
    },
  },
  formLabel: {
    color: theme.palette.typography.primary,
    lineHeight: '1.5rem',
    fontWeight: '500',
  },
  formLabelFocused: {
    color: theme.palette.typography.primary,
  },
  errored: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeft: `4px solid ${ColorPalette.red00}`,
    paddingLeft: theme.spacing(2),
  },
  checkboxRoot: {
    padding: theme.spacing(1),
    paddingTop: '6px',
    color: theme.palette.inputs.selectBorder,
  },
  checkboxChecked: {
    color: theme.palette.typography.primary,
  },
  errorText: {
    fontWeight: '500',
    fontSize: '1rem',
    lineHeight: 1.5,
    margin: 0,
  },
  option: {
    color: theme.palette.typography.primary,
  },
  disabled: {
    color: theme.palette.typography.disabled,
  },
}));

const FdCheckbox = ({
  onChange,
  name,
  options,
  error,
  defaultSelected,
  multiple,
  disabled,
  helperText,
  label,
  labelPlacement,
  formLegend,
  disabledList,
  value,
  checked,
  selectAll,
  caption,
  maxSelection,
  ...props
}) => {
  const keys = Object.keys(options);
  const optionValues = Object.values(options);
  const containsCaptions = isObjectArray(optionValues);
  const captions = containsCaptions
    ? optionValues?.map((ov) => ov?.caption)
    : [];

  const values = containsCaptions
    ? optionValues?.map((ov) => ov?.label)
    : optionValues;

  const initSelectableOptions = () => {
    let selOptions = keys;
    if (disabledList?.length > 0) {
      selOptions = keys?.filter((option) => !disabledList?.includes(option));
    }
    return selOptions;
  };

  const { classes, cx } = useStyles();
  const [selected, setSelected] = useState(defaultSelected);
  const [isChecked, setIsChecked] = useState(checked);
  const [selectableOptions] = useState(initSelectableOptions());
  const isAllSelected =
    selectableOptions?.length > 0 &&
    selected?.length === selectableOptions?.length;
  const isDetermine =
    selected?.length > 0 && selected?.length < selectableOptions?.length;

  const handleChange = (e) => {
    let selectedOptions = [];
    if (e.target.value === 'all') {
      selectedOptions =
        selected?.length === selectableOptions?.length ? [] : selectableOptions;
    } else if (selected?.includes(e.target.value)) {
      selectedOptions = selected?.filter((option) => option !== e.target.value);
    } else {
      selectedOptions = [...selected, e.target.value];
    }
    // enforce maxSelection options if send
    if (maxSelection && selectedOptions.length > maxSelection) {
      return;
    }
    setSelected(selectedOptions);
    onChange(selectedOptions);
  };

  const handleSingleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const Caption = ({ labelText, captionText }) => (
    <Box display="flex" alignItems="center">
      {labelText}
      <Box ml={1}>
        <FdTypography
          variant="captiontext1"
          aria-describedby={captionText}
          color="secondary"
        >
          {captionText}
        </FdTypography>
      </Box>
    </Box>
  );
  Caption.propTypes = {
    labelText: PropTypes.string.isRequired,
    captionText: PropTypes.string.isRequired,
  };

  return (
    <FormControl
      error={error}
      className={cx({
        [classes.errored]: error,
        [classes.formControl]: true,
      })}
      component="fieldset"
      disabled={disabled}
    >
      {formLegend ? (
        <FormLabel
          className={classes.formLabel}
          classes={{
            focused: classes.formLabelFocused,
          }}
          component="legend"
        >
          {formLegend}
        </FormLabel>
      ) : null}

      {multiple ? (
        <FormGroup>
          {selectAll && (
            <FormControlLabel
              label="Select all"
              control={
                <Checkbox
                  color="primary"
                  checked={isAllSelected}
                  value="all"
                  indeterminate={isDetermine}
                  onChange={handleChange}
                  className={cx({
                    [classes.checkboxRoot]: true,
                    [classes.errorText]: error,
                    [classes.checkboxChecked]: isAllSelected,
                  })}
                />
              }
            />
          )}
          {keys?.map((option, index) => (
            <FormControlLabel
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              control={
                <Checkbox
                  color="primary"
                  value={option}
                  checked={selected.includes(option)}
                  onChange={handleChange}
                  name={name}
                  disabled={disabled || disabledList?.includes(option)}
                  className={cx({
                    [classes.checkboxRoot]: true,
                    [classes.errorText]: error,
                    [classes.checkboxChecked]: selected?.indexOf(option) > -1,
                  })}
                />
              }
              label={
                captions?.[index] ? (
                  <Caption
                    labelText={values?.[index]}
                    captionText={captions?.[index]}
                  />
                ) : (
                  values?.[index]
                )
              }
              style={{ display: 'flex', alignItems: 'center' }}
            />
          ))}
        </FormGroup>
      ) : (
        <FormControlLabel
          labelPlacement={labelPlacement}
          control={
            <Checkbox
              {...props}
              color="primary"
              value={value}
              checked={isChecked}
              onChange={handleSingleChange}
              className={cx({
                [classes.errorText]: error,
                [classes.checkboxRoot]: true,
                [classes.checkboxChecked]: true,
              })}
            />
          }
          label={
            caption ? (
              <Caption labelText={label} captionText={caption} />
            ) : (
              label
            )
          }
          style={{ display: 'flex', alignItems: 'center' }}
        />
      )}
      {helperText && (
        <Box mt={0.5}>
          <FormHelperText id={`${name}-helper-text`}>
            {helperText}
          </FormHelperText>
        </Box>
      )}
    </FormControl>
  );
};

FdCheckbox.propTypes = {
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledList: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ label: PropTypes.string, caption: PropTypes.string }),
      ]),
    }),
  ),
  multiple: PropTypes.bool,
  checked: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  formLegend: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectAll: PropTypes.bool,
  caption: PropTypes.node,
  maxSelection: PropTypes.number,
};

FdCheckbox.defaultProps = {
  error: false,
  disabled: false,
  defaultSelected: [],
  multiple: false,
  checked: false,
  helperText: undefined,
  onChange: () => {},
  labelPlacement: 'end',
  options: {},
  name: '',
  formLegend: undefined,
  disabledList: [],
  value: undefined,
  label: undefined,
  selectAll: true,
  caption: undefined,
  maxSelection: undefined,
};

export default FdCheckbox;
