import { generateClient } from 'aws-amplify/api';
import { useQueries } from '@tanstack/react-query';
import { milliseconds } from 'date-fns';

const client = generateClient();

// fetch api recursively until nextToken is null
const recursiveFetch = (
  query,
  { variables, ...rest } = {},
  nextToken = null,
  items = [],
) =>
  new Promise((resolve, reject) => {
    client
      .graphql({
        query,
        variables: {
          ...variables,
          ...rest,
          nextToken,
        },
      })
      .then((result) => {
        // queryName follows the format data[queryName]
        const queryName = Object.keys(result?.data)[0];
        const data = result?.data[queryName];

        if (data?.items) {
          // eslint-disable-next-line no-param-reassign
          items = [...items, ...(data?.items ?? [])]; // merge items from previous fetch
        }
        // if nextToken present then call the fetch again with nextToken
        if (data?.nextToken) {
          recursiveFetch(query, { variables, ...rest }, data?.nextToken, items)
            .then((nextResult) => resolve(nextResult))
            .catch((err) => reject(err));
        } else if (data?.items) {
          resolve({
            [queryName]: {
              items: [...items],
            },
          });
        } else {
          resolve({
            [queryName]: {
              ...data,
            },
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

const useQueriesRecursive = (
  graphqlQuery,
  { variables, recurringVariables, skip, staleTime, ...rest } = {},
) => {
  // set a default limit value if not set
  const limit = variables?.limit || 100;

  const queryName = graphqlQuery?.definitions[0]?.name?.value || 'invalidQuery';
  const outputQueryName =
    queryName.charAt(0).toLowerCase() + queryName.slice(1);

  const queries = recurringVariables?.map((rv) => {
    return {
      queryKey: [queryName, { ...variables, ...rv }],
      queryFn: async () => {
        const result = await recursiveFetch(graphqlQuery, {
          ...variables,
          ...rv,
          limit,
        });
        return result;
      },
      enabled: !skip,
      staleTime: milliseconds(staleTime || { minutes: 1 }),
      ...rest,
    };
  });

  // fetch query
  const results = useQueries({ queries });

  const isFetching = results.some((result) => result.isFetching);
  const error = results.some((result) => result.error);
  const data = results.map((result) => result.data?.[outputQueryName]?.items);

  return {
    data: { [outputQueryName]: { items: data?.flat() || [] } },
    loading: isFetching,
    error,
  };
};

export default useQueriesRecursive;
