import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useSnapshot } from 'valtio';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from './Logo';
import FdTooltip from '../FdTooltip';
import useStyles from './styles';
import { useAppTheme, useLocalStorage } from '../../hooks';
import { listUrls } from '../../queries/customQueries';
import { globalStore } from '../../shared';
import FdTypography from '../FdTypography';
import FdLightLogo from './images/fd-light-logo.png';
import FdDarkLogo from './images/fd-dark-logo.png';
import FdLogoSmall from './FdLogoSmall';
import FdDelayed from '../FdDelayed';
import { GLOBAL_EVENTS } from '../../constants';
import {
  triggerGlobalEvent,
  useGlobalEventListener,
} from '../../hooks/globalStore/useGlobalEventListener';

const SideNav = ({
  navigationItems,
  handleOnSelectedItem,
  mainPageIds,
  selected,
  selectedParent,
  defaultOpen,
  onDrawerOpenClose,
  MenuHeader,
}) => {
  const { classes } = useStyles();
  const [openSubMenus, setOpenSubMenus] = useState(
    selectedParent ? { [selectedParent]: true } : {},
  );
  const [selectedItem, setSelectedItem] = useState(selected);
  const [selectedParentItem, setSelectedParentItem] = useState(selectedParent);
  const theme = useAppTheme();
  const headers = document.getElementsByTagName('header');
  if (headers.length > 0) {
    headers[0].style.backgroundColor = theme.palette.background.sidebar;
  }
  const globalSnap = useSnapshot(globalStore);
  const [userPreferencesSidebar, setUserPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar',
    {
      defaultValue: {
        [globalSnap?.userId]: {
          sidebarExpanded: defaultOpen,
        },
      },
    },
  );
  const [open, setOpen] = useState(
    () =>
      userPreferencesSidebar?.[globalSnap?.userId]?.sidebarExpanded ??
      defaultOpen,
  );

  const { data: urlData } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: globalSnap.orgId },
        theme: { eq: globalSnap?.theme?.toUpperCase() },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSelectedItem(selected);
  }, [mainPageIds, open, selected]);

  const handleDrawerOpenClose = (_drawerState) => {
    const drawerState = _drawerState !== undefined ? _drawerState : !open;
    // call optional drawer open/close event handler
    onDrawerOpenClose?.();
    setOpen(_drawerState !== undefined ? _drawerState : drawerState);
    setUserPreferencesSidebar({
      ...userPreferencesSidebar,
      [globalSnap?.userId]: {
        sidebarExpanded: drawerState,
      },
    });
    // if drawer open then close
    if (drawerState) {
      triggerGlobalEvent(GLOBAL_EVENTS.CLOSE_TASK_DRAWER);
    }
  };

  useGlobalEventListener(GLOBAL_EVENTS.CLOSE_SIDEBAR, () => {
    // close sidebar on global event
    handleDrawerOpenClose(false);
  });

  const handleSubmenuClick = (name) => () => {
    const newSubMenus = { ...openSubMenus };
    if (openSubMenus[name] === undefined) {
      newSubMenus[name] = true;
    }
    newSubMenus[name] = !openSubMenus[name];
    setOpenSubMenus(newSubMenus);
  };

  const handleSelected = (name, path, parentName) => () => {
    handleOnSelectedItem(name, path);

    if (parentName) {
      setSelectedParentItem(parentName);
    } else {
      setSelectedParentItem(null);
    }
  };

  const arrowButton = () => {
    return open ? <ChevronLeftIcon /> : <ChevronRightIcon id="icon-right" />;
  };

  const parentListItemSelected = (name) => {
    if (selectedParentItem) {
      if (selectedParentItem === name) {
        return open
          ? classes.selectedParentItem
          : classes.selectedParentItemClosed;
      }
    }

    return selectedItem === name ? classes.selectedItem : classes.item;
  };

  const listItemIconClass = (name) => {
    if (!open && selectedParentItem === name) {
      return classes.listItemIconSelected;
    }
    if (selectedItem === name) {
      return classes.listItemIconSelected;
    }
    return classes.listItemIcon;
  };

  const BottomLogo = () => {
    return (
      <Box className="text-center">
        <FdTypography variant="captiontext1">Powered by</FdTypography>
        <img
          src={theme.palette.mode === 'light' ? FdLightLogo : FdDarkLogo}
          className={classes.logoBottom}
          alt="FifthDomain Logo"
        />
      </Box>
    );
  };

  const key = urlData?.listUrls?.items?.[0]?.key;

  const isWhiteLabelledOrgs = key !== undefined;
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      onClick={() => {
        if (!open && mainPageIds) {
          handleDrawerOpenClose(true);
        }
      }}
    >
      <Box className="flex flex-col justify-between h-screen">
        <Box>
          <Box height={225} className="flex items-center justify-center">
            {open ? (
              isWhiteLabelledOrgs ? (
                <Logo className={classes.logo} logo={key} isWhiteLabelled />
              ) : (
                <Logo isWhiteLabelled={false} />
              )
            ) : (
              <div className={classes.logoClosed} />
            )}
          </Box>
          <div id="toolbar" className={classes.toolbar}>
            <IconButton
              id="icon-btn"
              onClick={() => handleDrawerOpenClose()}
              classes={{ root: classes.chevron }}
              size="large"
            >
              {arrowButton()}
            </IconButton>
          </div>
          {open && <MenuHeader />}
          <Box
            style={{
              maxHeight: 'calc(100vh - 430px)',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <List className={open ? classes.listOpen : classes.listClosed}>
              {navigationItems.map(
                ({ name, path, icon, dataCy, children, caption }) => (
                  <React.Fragment key={shortid.generate()}>
                    <ListItem
                      onClick={
                        children
                          ? handleSubmenuClick(name)
                          : handleSelected(name, path)
                      }
                      className={parentListItemSelected(name)}
                    >
                      <FdTooltip title={name} arrow>
                        <ListItemIcon
                          classes={{
                            root: listItemIconClass(name),
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                      </FdTooltip>
                      {children ? (
                        <ListItemText
                          classes={{
                            primary: open ? classes.heavyText : classes.hidden,
                          }}
                          primary={name}
                          data-cy={dataCy || name}
                        />
                      ) : (
                        <Box className="flex flex-col justify-around">
                          <ListItemText
                            primary={name}
                            classes={{
                              primary: open
                                ? classes.heavyText
                                : classes.hidden,
                            }}
                            style={{ margin: caption ? 0 : '4px' }}
                            data-cy={dataCy || name}
                          />
                          {open && caption && (
                            <FdTypography
                              variant="captiontext2"
                              color="secondary"
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '170px',
                              }}
                            >
                              {caption}
                            </FdTypography>
                          )}
                        </Box>
                      )}
                      {open &&
                        children &&
                        (openSubMenus[name] ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {children && (
                      <Collapse
                        in={openSubMenus[name]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {children.map((child) => (
                            <React.Fragment key={shortid.generate()}>
                              <ListItem
                                className={clsx(
                                  open ? classes.nested : classes.nestedClosed,
                                  {
                                    [classes.selectedItem]:
                                      selectedItem === child.name && open,
                                  },
                                )}
                                onClick={handleSelected(
                                  child.name,
                                  child.path,
                                  name,
                                )}
                              >
                                {child?.icon && (
                                  <FdTooltip title={child?.name} arrow>
                                    <ListItemIcon
                                      classes={{
                                        root: listItemIconClass(child?.name),
                                      }}
                                    >
                                      {child?.icon}
                                    </ListItemIcon>
                                  </FdTooltip>
                                )}
                                <ListItemText
                                  primary={child.name}
                                  data-cy={child.dataCy || child.name}
                                />
                              </ListItem>
                            </React.Fragment>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                ),
              )}
            </List>
          </Box>
          <Divider />
        </Box>
        <Box>
          {open && (
            <FdDelayed delay={100} hideProgress>
              <Box className={classes.logoBottomContainer}>
                <BottomLogo />
              </Box>
            </FdDelayed>
          )}
          {!open && <FdLogoSmall />}
        </Box>
      </Box>
    </Drawer>
  );
};

SideNav.propTypes = {
  handleOnSelectedItem: PropTypes.func.isRequired,
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.element,
    }).isRequired,
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,
  selected: PropTypes.string,
  selectedParent: PropTypes.string,
  defaultOpen: PropTypes.bool,
  onDrawerOpenClose: PropTypes.func,
  MenuHeader: PropTypes.node,
};

SideNav.defaultProps = {
  mainPageIds: [],
  selected: null,
  selectedParent: null,
  defaultOpen: true,
  onDrawerOpenClose: undefined,
  MenuHeader: () => null,
};

export default SideNav;
