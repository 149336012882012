export const generateLoaderSpecialties = (borderColor) => {
  const dataTemplate = () =>
    Array.from({ length: 5 }, (_, i) => ({
      x: 5 - i,
      yBackgroundColor: borderColor,
      yBorderColor: borderColor,
      yBorderStyle: 'border-solid',
    }));

  const _specialtiesLoading = Array.from({ length: 6 }, (__, i) => ({
    flip: i % 2 === 0,
    levels: [5, 4, 3, 2, 1],
    dataSorted: Array.from(
      { length: [0, 1, 4, 5].includes(i) ? 6 : 5 },
      () => ({
        id: undefined,
        data: dataTemplate('alias'),
      }),
    ),
  }));

  return _specialtiesLoading;
};
