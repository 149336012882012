import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import { alpha, useTheme } from '@mui/material';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel';
import globalStore from '../../store';

const FdCarousel = ({ slides, currentSlide, ...props }) => {
  const theme = useTheme();
  const [api, setApi] = React.useState();
  const { isSmallScreen } = useSnapshot(globalStore);
  const minCards = isSmallScreen ? 1 : 3;

  useEffect(() => {
    if (!api) {
      return;
    }
    api.scrollTo(currentSlide - 1);
  }, [currentSlide, api]);

  const buttonStyle = {
    color: theme?.palette?.primary?.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    '--hover-border-color': theme?.palette?.primary?.main,
  };

  if (!slides || slides.length === 0) {
    return null;
  }

  return (
    <Carousel setApi={setApi} className="mx-14" {...props}>
      <CarouselContent className="flex-nowrap cursor-default">
        {slides.map((slide, index) => (
          <CarouselItem
            key={slide?.id || index}
            style={{
              flex: `0 0 ${100 / Math.min(slides.length, minCards)}%`,
              maxWidth: `${100 / Math.min(slides.length, minCards)}%`,
            }}
          >
            {slide?.content}
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious
        className="border-transparent hover:border"
        style={buttonStyle}
      />
      <CarouselNext
        className="border-transparent hover:border"
        style={buttonStyle}
      />
    </Carousel>
  );
};

FdCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  currentSlide: PropTypes.number,
};

FdCarousel.defaultProps = {
  slides: [],
  currentSlide: 0,
};

export default FdCarousel;
