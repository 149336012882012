import { LicenseInfo } from '@material-ui/x-grid';
import { LicenseInfo as MuiProLicenseInfo } from '@mui/x-license-pro';
import Tabs from './Tabs';
import InformationBox from './widgets';
import { FdLoadingCard, FdLoadingSpinner } from './FdLoading';
import GenericRoute from './GenericRoute';
import VDIConsole from './VDIConsole';
import FdButton from './FdButton';
import FdTable from './FdTable';
import FdChip from './FdChip';
import FdBreadcrumbs from './FdBreadcrumbs';
import FdTypography from './FdTypography';
import FdSelect from './FdSelect';
import FdAutocomplete from './FdAutocomplete';
import FdAutocompleteStatic from './FdAutocompleteStatic';
import FdCheckbox from './FdCheckbox';
import FdDropdownMenu from './FdDropdownMenu';
import FdModal from './FdModal';
import FdMarkdownEditor from './FdMarkdownEditor';
import FdTextField from './FdTextField';
import FdTab from './FdTab';
import FdAlert from './FdAlert';
import FdCardMini from './FdCardMini';
import FdCard from './FdCard';
import FdRadioGroup from './FdRadioGroup';
import FdRadioScale from './FdRadioScale';
import SideNav from './SideNav';
import S3Image from './SideNav/S3Image';
import FdAccordion from './FdAccordion';
import FdDateTimePicker from './FdDateTimePicker';
import FdMarkdownRender from './FdMarkdownRender';
import {
  XgridLicenseKey,
  MuiProLicenseKey,
  MAP_API_KEY,
} from '../shared/license';
import FdSwitch from './FdSwitch';
import FdTopNav from './FdTopNav';
import FdToggleButtons from './FdToggleButtons';
import FdBadge from './FdBadge';
import FdAvatar from './FdAvatar';
import FdAvatarGroup from './FdAvatarGroup';
import FdTooltip from './FdTooltip';
import FdExternalLink from './FdExternalLink/FdExternalLink';
import FdMultiChipTextField from './FdMultiChipTextField';
import FdStepper from './FdStepper';
import FdProgress from './FdProgress';
import FdLab from './FdLab';
import FdDelayed from './FdDelayed';
import FdCountdown from './FdCountdown';
import FdChatDrawer from './FdChatDrawer';
import FdOrgLogo from './FdOrgLogo/FdOrgLogo';
import FdChips from './FdChips';
import FdCardList from './FdCardList';
import FdMUIMultipleAutocomplete from './FdMUIMultipleAutocomplete';
import FdChatbot from './FdChatbot';
import FdSkeleton from './FdSkeleton';
import FdCircularProgressBar from './FdCircularProgressBar';
import FdIconWithTooltip from './FdIconWithTooltip';
import FdChatStream from './FdChatStream';
import FdCortex from './FdCortex';
import FdFileAttachment from './FdFileAttachment';
import FdAnimatedDigit from './FdAnimatedDigit';
import '../main.css';
// Set license key for Xgrid
LicenseInfo.setLicenseKey(XgridLicenseKey);
// Set license key for MUI Pro
MuiProLicenseInfo.setLicenseKey(MuiProLicenseKey);

export {
  FdAccordion,
  FdDateTimePicker,
  SideNav,
  GenericRoute,
  VDIConsole,
  Tabs,
  InformationBox,
  FdLoadingCard,
  FdLoadingSpinner,
  FdButton,
  FdTab,
  FdTable,
  FdChip,
  FdBreadcrumbs,
  FdTypography,
  FdSelect,
  FdAutocomplete,
  FdAutocompleteStatic,
  FdCheckbox,
  FdDropdownMenu,
  FdModal,
  FdMarkdownEditor,
  FdTextField,
  FdAlert,
  FdCardMini,
  FdCard,
  FdRadioGroup,
  FdRadioScale,
  FdMarkdownRender,
  FdExternalLink,
  FdSwitch,
  FdTopNav,
  FdToggleButtons,
  FdBadge,
  FdAvatar,
  FdAvatarGroup,
  FdTooltip,
  FdMultiChipTextField,
  FdStepper,
  FdProgress,
  FdLab,
  FdDelayed,
  S3Image,
  FdCountdown,
  FdChatDrawer,
  FdOrgLogo,
  FdChips,
  FdCardList,
  FdMUIMultipleAutocomplete,
  FdChatbot,
  FdSkeleton,
  FdCircularProgressBar,
  FdIconWithTooltip,
  FdChatStream,
  FdCortex,
  MAP_API_KEY,
  FdFileAttachment,
  FdAnimatedDigit,
};
