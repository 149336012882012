import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Typography,
  InputLabel,
  FormHelperText,
} from '@mui/material/';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';
import { isArrayWithKeys } from '../../shared/objectUtils';

// TODO jss-to-tss-react codemod: '@global' is not supported by tss-react.
// See https://mui.com/material-ui/customization/how-to-customize/#4-global-css-override for alternatives.
const useStyles = makeStyles()((theme, _params, classes) => ({
  // global scrollbar, style applying to all scrollbars
  '@global': {
    '*::-webkit-scrollbar': {
      width: 4,
      borderRadius: '0 4px',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: ColorPalette.grey02,
      borderRadius: 4,
      height: 67,
    },
  },
  formControl: {
    [`& .${classes.selectRoot}`]: {
      display: 'flex',
      [`&.${classes.muiSelectOutlined}`]: {
        backgroundColor: theme.palette.inputs?.background,
        padding: '19px',
        paddingLeft: '10px',
        '& .labelTitle': {
          maxWidth: '68%',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        '& .labelCount': {
          paddingLeft: 5,
        },
      },
    },
    '& [class*="Mui-selected"] span': {
      fontWeight: '600',
    },
    '& [class*="MuiSelect-select"]': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '8px',
    },
    '& [class*="MuiInputLabel-outlined"]': {
      transform: 'translate(14px, 21px) scale(1)',
      color: ColorPalette.black00,
    },
    '& [class*="MuiFormLabel-root"]': {
      color: theme.palette.typography.primary,
      top: '-3px',
    },
    '& [class*="MuiOutlinedInput-notchedOutline"] legend': {
      maxWidth: '0',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.contrastText,
    },
    '& [class*="Mui-focused"]': {
      '& fieldset': {
        borderColor: theme.palette.inputs?.borderHover,
        borderWidth: '1px !important',
      },
    },
  },
  formControlCustomPlaceHolder: {
    '& [class*="MuiInputBase-root"]': {
      lineHeight: '0.1rem',
    },
    '& [class*="MuiFormLabel-root"]': {
      color: theme.palette.primary.contrastText,
    },
    '& [class*="MuiSelect-icon"]': {
      color: theme.palette.primary.contrastText,
    },
    '& [class*="MuiInputLabel-formControl"]': {
      top: '-14px',
      fontSize: 14,
      fontWeight: 600,
    },
  },
  selectRoot: {},
  selectRootCustomPlaceHolder: {
    height: '34px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
    },
  },
  muiSelectOutlined: {},
  paperRoot: {
    '& [class*="MuiListItemIcon-root"]': {
      minWidth: '0',
    },
    '& [class*="MuiListItem-gutters"]': {
      paddingLeft: '5px',
    },
    '& [class*="MuiSelect-selectMenu"] div': {
      margin: '2px',
    },
  },
  errorText: {
    color: ColorPalette.red00,
  },
  chipOpened: {
    margin: '0 3px',
  },
  chipClosed: {
    margin: '0 3px',
    maxWidth: '100px',
  },
  disabled: {
    color: `${theme.palette.background.disabled} !important`,
  },
  formHelperSpacing: {
    marginLeft: '0',
    marginTop: '0',
  },
  chipContainer: {
    overflow: 'visible',
    whiteSpace: 'normal',
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const FdSelect = ({
  inputTitle,
  multiple,
  options,
  helperText,
  error,
  chipCountWhenClosed,
  menuLabel,
  width,
  fullWidth,
  id,
  readOnly,
  defaultSelected,
  showSelectAllOption,
  showAllOptions,
  onChange,
  placeholder,
  label,
  disabled,
  customPlaceHolder,
  ...props
}) => {
  const { classes } = useStyles();
  const optionsAreObjects = isArrayWithKeys(options, 'id', 'name');
  const initSelected = () => {
    let selSelected = [];
    if (defaultSelected) {
      selSelected = multiple ? defaultSelected : [defaultSelected];
    }
    return selSelected;
  };
  const [selected, setSelected] = useState(initSelected());
  const isAllSelected =
    options.length > 0 && selected?.length === options?.length;
  const _placeholder = placeholder || menuLabel;
  const _label = label || inputTitle;
  const _readOnly = disabled || readOnly;
  const handleChange = (e) => {
    const { value } = e.target;
    if (value[value.length - 1] === 'all') {
      const selectedOptions = selected.length === options.length ? [] : options;
      setSelected(selectedOptions);
      onChange(selectedOptions);
      return;
    }
    setSelected(value);
    onChange(value);
  };
  return (
    <Box
      display={fullWidth ? 'flex' : 'inline-flex'}
      flexDirection="column"
      width={fullWidth ? 'auto' : width}
    >
      {_label && (
        <Typography
          variant="subtitle1"
          className={_readOnly ? classes.disabled : ''}
        >
          {_label}
        </Typography>
      )}
      <FormControl
        variant="outlined"
        className={`${classes.formControl} ${
          customPlaceHolder && classes.formControlCustomPlaceHolder
        }`}
        error={error}
        disabled={_readOnly}
      >
        {selected?.length === 0 && (
          <InputLabel
            htmlFor={id}
            id={`${id}-label`}
            shrink={false}
            className={disabled && classes.disabled}
          >
            {_placeholder}
          </InputLabel>
        )}
        {multiple ? (
          <Select
            {...props}
            labelId={`${id}-label`}
            id={id}
            multiple
            value={selected}
            onChange={handleChange}
            label={_placeholder || undefined}
            classes={{
              root: classes.selectRoot,
              outlined: classes.muiSelectOutlined,
            }}
            inputProps={{
              'aria-label': _placeholder || 'Without Label',
              disabled: _readOnly,
              error: error ? classes.error : '',
              'aria-describedby': `${id}-helper-text`,
            }}
            renderValue={(selectedList) => {
              if (selectedList?.length === 0) {
                return <em>Placeholder</em>;
              }
              const selectedOptions = optionsAreObjects
                ? options
                    .filter((option) => selectedList.includes(option.id))
                    ?.map((_option) => _option.name)
                : selectedList;

              return (
                <>
                  <span className="labelTitle">{`${selectedOptions
                    .slice(0, chipCountWhenClosed)
                    ?.join(', ')}`}</span>
                  {selectedOptions?.length > chipCountWhenClosed ? (
                    <span className="labelCount">{`${` + ${
                      (selectedOptions?.length || 0) - chipCountWhenClosed
                    }`} more `}</span>
                  ) : (
                    ''
                  )}
                </>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: showAllOptions
                    ? '100%'
                    : ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
              classes: {
                paper: classes.paperRoot,
              },
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              variant: 'menu',
            }}
          >
            {showSelectAllOption && (
              <MenuItem value="all">
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select All" />
              </MenuItem>
            )}
            {options.map((option) => {
              const optionValue = optionsAreObjects ? option?.id : option;
              const optionLabel = optionsAreObjects ? option?.name : option;

              return (
                <MenuItem key={optionValue} value={optionValue}>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={selected && selected.indexOf(optionValue) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={optionLabel} />
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <Select
            {...props}
            labelId={`${id}-label`}
            id={id}
            value={selected}
            label={_placeholder}
            onChange={handleChange}
            classes={{
              root: customPlaceHolder
                ? classes.selectRootCustomPlaceHolder
                : classes.selectRoot,
              outlined: classes.muiSelectOutlined,
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            }}
            inputProps={{
              'aria-label': _placeholder || 'Without Label',
              disabled: _readOnly,
              error: error ? classes.error : '',
              'aria-describedby': `${id}-helper-text`,
            }}
          >
            {optionsAreObjects
              ? options?.map((option) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                ))
              : options?.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
          </Select>
        )}
        {helperText && (
          <Box mt={0.5}>
            <FormHelperText
              classes={{
                root: classes.formHelperSpacing,
              }}
              id={`${id}-helper-text`}
            >
              {helperText}
            </FormHelperText>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};
FdSelect.propTypes = {
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  showSelectAllOption: PropTypes.bool,
  id: PropTypes.string.isRequired,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  menuLabel: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, id: PropTypes.string }),
    ),
  ]).isRequired,
  multiple: PropTypes.bool,
  showAllOptions: PropTypes.bool,
  inputTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  chipCountWhenClosed: PropTypes.number,
  /** To grow fullwidth of parent */
  fullWidth: PropTypes.bool,
  /** Custom width */
  width: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  customPlaceHolder: PropTypes.bool,
};
FdSelect.defaultProps = {
  error: false,
  readOnly: false,
  disabled: false,
  showSelectAllOption: true,
  showAllOptions: false,
  defaultSelected: undefined,
  menuLabel: undefined,
  multiple: false,
  inputTitle: null,
  helperText: null,
  chipCountWhenClosed: 1,
  fullWidth: false,
  width: '276px',
  onChange: () => {},
  placeholder: 'Select',
  label: '',
  customPlaceHolder: false,
};

export default FdSelect;
