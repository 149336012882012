import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FdTypography from '../FdTypography';
import FdTextField from '../FdTextField';
import FdButton from '../FdButton';

const drawerWidth = 400;

const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    width: drawerWidth,
  },
}));

function Controls({
  setShowControls,
  enterFullscreen,
  updateClipboard,
  pasteClipboard,
  ctrlAltDelete,
  clipboardData,
  showControls,
}) {
  const [clipboard, setClipboard] = useState('');
  const { classes } = useStyles();

  useEffect(() => {
    setClipboard(clipboardData);
  }, [clipboardData]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={showControls}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="row"
        >
          <FdTypography variant="h3">Clipboard</FdTypography>
          <IconButton
            onClick={() => {
              setShowControls(false);
            }}
            size="large"
          >
            <CloseIcon style={{ fontSize: 25 }} />
          </IconButton>
        </Box>
        <Divider />
        <Box mt={1} />
        <FdTextField
          id="clipboard-field"
          multiline
          fullWidth
          minRows="10"
          maxRows="13"
          placeholder="Copied text will appear here."
          value={clipboard}
          onChange={(e) => {
            setClipboard(e.target.value);
          }}
        />
        <Box mt={1} mb={3} display="flex" justifyContent="space-around">
          <FdButton
            size="large"
            endIcon={<ContentPasteIcon />}
            onClick={() => updateClipboard()}
          >
            Copy
          </FdButton>
          <FdButton
            size="large"
            endIcon={<ContentPasteGoIcon />}
            onClick={(e) => {
              pasteClipboard(clipboard);
              e.currentTarget.blur();
            }}
          >
            Paste
          </FdButton>
        </Box>
        <FdTypography variant="h3">Controls</FdTypography>
        <Divider />
        <Box mt={2} mb={3} display="flex" justifyContent="space-around">
          <FdButton
            size="large"
            endIcon={<RestartAltIcon />}
            onClick={() => ctrlAltDelete()}
          >
            Ctrl-Alt-Del
          </FdButton>
          <FdButton
            size="large"
            endIcon={<FullscreenIcon />}
            onClick={() => enterFullscreen()}
          >
            Fullscreen
          </FdButton>
        </Box>
      </Box>
    </Drawer>
  );
}

Controls.propTypes = {
  setShowControls: PropTypes.func.isRequired,
  enterFullscreen: PropTypes.func.isRequired,
  updateClipboard: PropTypes.func.isRequired,
  pasteClipboard: PropTypes.func.isRequired,
  ctrlAltDelete: PropTypes.func.isRequired,
  showControls: PropTypes.bool.isRequired,
  clipboardData: PropTypes.string.isRequired,
};

export default Controls;
