import React from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, Menu, MenuItem, Checkbox } from '@mui/material';
import useStyles from './style';

const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
};

const DEFAULT_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'left',
};

export const mutualExclusiveSelect = (id, setSelected) => {
  const sel = [id];
  setSelected(sel);
};

export const toggleSelect = (
  currentSelected,
  id,
  setSelected,
  allOptions = [],
) => {
  if (id === 'selectall') {
    if (currentSelected.includes('selectall')) {
      setSelected([]);
    } else {
      setSelected([...allOptions, 'selectall']);
    }
    return;
  }

  const sel = currentSelected.slice();
  const index = currentSelected.indexOf(id);
  if (index === -1) {
    sel.push(id);
  } else {
    sel.splice(index, 1);
  }

  setSelected(sel);
};

export function FdDropdownMenu({
  setAnchor,
  anchor,
  id,
  menuOptions,
  anchorOrigin,
  transformOrigin,
  selectedMenuItems,
  menuCallback,
  variant,
  closeOnSelect,
  isCheckbox,
  selectAllOption,
  divider,
}) {
  const { classes } = useStyles({ variant });
  const onMenuClose = (isActive) => (isActive ? setAnchor(null) : null);
  const anchorOriginParsed = anchorOrigin || DEFAULT_ANCHOR_ORIGIN;
  const transformOriginParsed = transformOrigin || DEFAULT_TRANSFORM_ORIGIN;

  const renderIcon = (selected, Icon, SelectedIcon) => {
    if (selected && SelectedIcon) {
      return (
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <SelectedIcon className={classes.icon} />
        </ListItemIcon>
      );
    }

    if (Icon) {
      return (
        <ListItemIcon
          classes={{
            root: classes.listItemIcon,
          }}
        >
          <Icon className={classes.icon} />
        </ListItemIcon>
      );
    }

    return (
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Checkbox
          checked={selected}
          color="primary"
          size="medium"
          disableRipple
          classes={{ root: classes.iconButtonRoot }}
        />
      </ListItemIcon>
    );
  };

  const renderMenuItem = (option, index) => {
    // check if the 2 posible formats for the id are in the selected items
    const selected = selectedMenuItems
      ? selectedMenuItems.includes(
          option.id ? option.id : `${id}-menu-item-${index}`,
        )
      : false;

    return (
      <MenuItem
        variant={variant}
        id={option.id || `${id}-menu-item-${index}`}
        onClick={(event) => {
          menuCallback(event);
          onMenuClose(closeOnSelect);
        }}
        key={option.id}
        className={[classes.menuItem, classes.variantClass].join(' ')}
        selected={selected}
        classes={{
          root: divider && index !== menuOptions.length - 1 && classes.divider,
          selected: classes.selectedMenuItem,
        }}
      >
        {(option.icon &&
          (!option.iconDirection || option.iconDirection === 'left')) ||
        isCheckbox
          ? renderIcon(selected, option.icon, option.selectedIcon)
          : null}
        {option.name}
        {(option.icon && option.iconDirection === 'right') || isCheckbox
          ? renderIcon(selected, option.icon, option.selectedIcon)
          : null}
      </MenuItem>
    );
  };

  return (
    <Menu
      id={id}
      keepMounted
      anchorEl={anchor}
      getContentAnchorEl={null}
      anchorOrigin={anchorOriginParsed}
      transformOrigin={transformOriginParsed}
      open={Boolean(anchor)}
      onClose={onMenuClose}
      MenuListProps={{ disablePadding: true }}
    >
      {selectAllOption &&
        renderMenuItem(
          { id: 'selectall', name: 'Select All', divider: true },
          'selectall',
        )}
      {menuOptions.map((option, index) => renderMenuItem(option, index))}
    </Menu>
  );
}

FdDropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  /**  The details of the menu items */
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.func,
      iconDirection: PropTypes.oneOf(['left', 'right']),
    }).isRequired,
  ).isRequired,
  anchor: PropTypes.object,
  /** The element that the menu is to be anchored to */
  setAnchor: PropTypes.func.isRequired,
  /** Used to determine the anchor point of the menu
      Use anchor playground to help position popup
      https://material-ui.com/components/popover/ */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** Used to determine the anchor point of the menu
    Use anchor playground to help position popup
    https://material-ui.com/components/popover/ */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  /** An array of ids that determines which menu items are selected */
  selectedMenuItems: PropTypes.arrayOf(PropTypes.string),
  /** The function to be executed when a menu item is clicked, passes up event */
  menuCallback: PropTypes.func.isRequired,
  /** determines the size of the menu items */
  variant: PropTypes.oneOf(['medium', 'large']).isRequired,
  /** determines whether the menu closes when clicked on. */
  closeOnSelect: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  /** non-interactive component at the top of the menu */
  selectAllOption: PropTypes.bool,
  divider: PropTypes.bool,
};

FdDropdownMenu.defaultProps = {
  anchor: null,
  anchorOrigin: null,
  transformOrigin: null,
  selectedMenuItems: null,
  closeOnSelect: false,
  isCheckbox: false,
  selectAllOption: false,
  divider: false,
};
