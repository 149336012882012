import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';
import FdButton from '../FdButton';

// isolate chatText watch from main form to avoid re-render
const ChatSendButton = ({ addChatMessage, isDirty }) => {
  const [dirtyStatus, setDirtyStatus] = useState(false);
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const watchChatText = useWatch({
    control,
    name: 'chatText',
  });

  // track isDirty status with a local state - call isDirty only once on state change
  useEffect(() => {
    if (watchChatText?.length > 0) {
      setDirtyStatus(true);
      if (!dirtyStatus) {
        isDirty(true);
      }
    } else {
      setDirtyStatus(false);
      if (dirtyStatus) {
        isDirty(false);
      }
    }
  }, [watchChatText, dirtyStatus, isDirty]);

  return (
    <Box mt={1}>
      <FdButton
        disabled={
          watchChatText?.trim() === '' || errors?.chatText || !watchChatText
        }
        onClick={addChatMessage}
      >
        Send
      </FdButton>
    </Box>
  );
};

ChatSendButton.propTypes = {
  addChatMessage: PropTypes.func.isRequired,
  isDirty: PropTypes.func.isRequired,
};

export default ChatSendButton;
