import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

function SearchBox({ apiRef, setSearching }) {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const updateSearchValue = React.useMemo(() => {
    return _.debounce((newValue) => {
      apiRef.current.setQuickFilterValues(
        newValue.split(' ').filter((word) => word !== ''),
      );
    }, 200);
  }, [apiRef]);

  const handleSearchValueChange = (event) => {
    const newValue = event.target.value;
    setText(newValue);
    updateSearchValue(newValue);
  };

  return !open ? (
    <IconButton
      aria-label="search"
      onClick={() => {
        setText('');
        setOpen(true);
      }}
      size="large"
    >
      <SearchIcon />
    </IconButton>
  ) : (
    <TextField
      id="search"
      placeholder="Search"
      value={text}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            data-cy="clear search"
            onClick={() => {
              setText(' ');
              apiRef.current.setQuickFilterValues('');
              setOpen(false);
              setSearching(false);
            }}
            size="large"
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
      onChange={handleSearchValueChange}
      autoFocus
    />
  );
}

SearchBox.propTypes = {
  apiRef: PropTypes.shape({ current: PropTypes.shape() }).isRequired,
  setSearching: PropTypes.func.isRequired,
};

export default SearchBox;
