import { SPECIALTY_SORT } from '../constants';
import { getArrayByLength } from './objectUtils';

export const cortexSort = (a, b) =>
  (SPECIALTY_SORT[a?.specialtyName]?.order || 0) -
  (SPECIALTY_SORT[b?.specialtyName]?.order || 0);

export const getCortexLevels = (idx) => {
  const difficultyLevels = getArrayByLength(5, (__, i) => i + 1);
  const flip = (idx + 1) % 2 === 1;
  const levels = flip ? [...difficultyLevels].reverse() : difficultyLevels;
  return { levels, flip };
};

const cortexDarkCellColors = {
  1: { bgColor: '#7FE4FF', color: '#000000' },
  2: { bgColor: '#76D9F4', color: '#000000' },
  3: { bgColor: '#6CCDE8', color: '#000000' },
  4: { bgColor: '#60BFD9', color: '#000000' },
  5: { bgColor: '#55B2CB', color: '#000000' },
  6: { bgColor: '#4CA7C0', color: '#000000' },
  7: { bgColor: '#459DB6', color: '#000000' },
  8: { bgColor: '#3B91AA', color: '#FFFFFF' },
  9: { bgColor: '#3388A0', color: '#FFFFFF' },
  10: { bgColor: '#2A7D95', color: '#FFFFFF' },
  11: { bgColor: '#21738A', color: '#FFFFFF' },
  12: { bgColor: '#196981', color: '#FFFFFF' },
  13: { bgColor: '#105E75', color: '#FFFFFF' },
  14: { bgColor: '#075369', color: '#FFFFFF' },
  15: { bgColor: '#014C62', color: '#FFFFFF' },
};

const cortexLightCellColors = {
  1: { bgColor: '#E3F0FB', color: '#000000' },
  2: { bgColor: '#CFDDF9', color: '#000000' },
  3: { bgColor: '#BECDF8', color: '#000000' },
  4: { bgColor: '#AEBDF6', color: '#000000' },
  5: { bgColor: '#A6AFF2', color: '#000000' },
  6: { bgColor: '#A4A3ED', color: '#000000' },
  7: { bgColor: '#A298E8', color: '#000000' },
  8: { bgColor: '#A08CE3', color: '#FFFFFF' },
  9: { bgColor: '#9F82DF', color: '#FFFFFF' },
  10: { bgColor: '#9D78DB', color: '#FFFFFF' },
  11: { bgColor: '#9B6ED7', color: '#FFFFFF' },
  12: { bgColor: '#9A63D2', color: '#FFFFFF' },
  13: { bgColor: '#9850C4', color: '#FFFFFF' },
  14: { bgColor: '#973CB6', color: '#FFFFFF' },
  15: { bgColor: '#9527A7', color: '#FFFFFF' },
};

export const getCortexCellColor = (value = 0, isDarkTheme = false) => {
  if (value === 0) return { bgColor: 'inherit', color: 'inherit' };
  const colorCells = isDarkTheme ? cortexDarkCellColors : cortexLightCellColors;
  const clampedValue = Math.max(
    1,
    Math.min(Object.keys(colorCells).length, Math.floor(value)),
  );
  return colorCells[clampedValue];
};
