import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormHelperText, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ColorPalette } from '../../shared/colors';
import FdTypography from '../FdTypography';

const useStyles = makeStyles()((theme, _params, classes) => ({
  formControl: {
    width: '100%',
  },
  toggleButtonGroupRoot: {
    '& svg': {
      color: theme.palette.toggleButton.unSelectedColor,
    },
    '& span': {
      fontWeight: 'normal',
      color: theme.palette.typography?.primary,
    },
  },
  groupedHorizontal: {
    '&:not(:first-child)': {
      borderLeft: `1px solid ${ColorPalette.grey04}`,
    },
  },
  selected: {},
  disabled: {},
  toggleButtonRoot: {
    height: '40px',
    backgroundColor: theme.palette.background.default,
    color: ColorPalette.grey07,
    border: `1px solid ${ColorPalette.grey04}`,
    [`&.${classes.selected}`]: {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
      '& span': {
        color: theme.palette.background.paper,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${ColorPalette.grey04}`,
      },
      '& svg': {
        color: theme.palette.background.paper,
      },
    },
    [`&.${classes.disabled}`]: {
      '& svg': {
        color: theme.palette.background.disabled,
      },
    },
  },
  flexWidth: {
    minWidth: '35px',
    padding: '6px',
  },
  rootWidth: {
    width: '40px',
  },
  errored: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeft: `4px solid ${ColorPalette.red00}`,
    paddingLeft: theme.spacing(2),
  },
  label: {
    textTransform: 'capitalize',
  },
}));

const FdToggleButtons = ({
  id,
  label,
  options,
  onChange,
  defaultValues,
  error,
  exclusive,
  flexWidth,
  errorText,
  buttonTextCapitalize,
  tooltip,
  ...props
}) => {
  const { classes, cx } = useStyles();

  const renderToggleButton = (option) => {
    if (tooltip) {
      return (
        <Tooltip
          title={option?.tooltipLabel || option?.key}
          arrow
          disableFocusListener
        >
          <ToggleButton
            key={option?.key}
            value={option?.key}
            aria-label={option?.key}
            disableRipple
            disabled={option?.disabled}
            selected={defaultValues === option?.key}
            className={flexWidth ? classes.flexWidth : classes.rootWidth}
            classes={{
              root: classes.toggleButtonRoot,
              disabled: classes.disabled,
              selected: classes.selected,
              label: buttonTextCapitalize && classes.label,
            }}
            {...props}
          >
            {option?.content}
          </ToggleButton>
        </Tooltip>
      );
    }

    return (
      <ToggleButton
        key={option?.key}
        value={option?.key}
        aria-label={option?.key}
        disableRipple
        disabled={option?.disabled}
        className={flexWidth ? classes.flexWidth : classes.rootWidth}
        classes={{
          root: classes.toggleButtonRoot,
          disabled: classes.disabled,
          selected: classes.selected,
          label: buttonTextCapitalize && classes.label,
        }}
        {...props}
      >
        {option?.content}
      </ToggleButton>
    );
  };

  return (
    <FormControl
      error={error}
      className={cx({
        [classes.formControl]: true,
        [classes.errored]: error,
      })}
    >
      {label && (
        <FormLabel htmlFor={id}>
          <FdTypography color="primary" variant="subtitle1">
            {label}
          </FdTypography>
        </FormLabel>
      )}
      <ToggleButtonGroup
        id={id}
        exclusive={exclusive}
        style={{ width: 'max-content' }}
        value={defaultValues}
        classes={{
          root: classes.toggleButtonGroupRoot,
          groupedHorizontal: classes.groupedHorizontal,
        }}
        onChange={(event, newVal) => {
          if (newVal !== null) {
            onChange({
              id,
              value: newVal,
            });
          }
        }}
        {...props}
      >
        {options.map((option) => renderToggleButton(option))}
      </ToggleButtonGroup>
      {error && (
        <FormHelperText className={classes.errorText}>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
FdToggleButtons.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      content: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
      disabled: PropTypes.bool,
      tooltipLabel: PropTypes.string,
    }),
  ).isRequired,
  exclusive: PropTypes.bool,
  flexWidth: PropTypes.bool,
  defaultValues: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  buttonTextCapitalize: PropTypes.bool,
  tooltip: PropTypes.bool,
};

FdToggleButtons.defaultProps = {
  label: undefined,
  exclusive: true,
  flexWidth: false,
  defaultValues: undefined,
  error: false,
  errorText: 'This field is required',
  buttonTextCapitalize: false,
  tooltip: false,
};

export default FdToggleButtons;
