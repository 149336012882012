import { saveAs } from 'file-saver';

export const downloadFile = async (url, fileName) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');

    const blob = await response.blob();
    saveAs(blob, fileName);
  } catch (error) {
    console.error('File download failed:', error);
  }
};
