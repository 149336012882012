import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import FdTypography from '../FdTypography';
import FdMenuIcon from '../FdMenuIcon';

const FdPageHeading = ({ type, variant, children, ...otherProps }) => {
  return (
    <Box className="flex items-center gap-x-2">
      <FdMenuIcon type={type} {...otherProps} />
      <FdTypography variant={variant} {...otherProps}>
        {children}
      </FdTypography>
    </Box>
  );
};

FdPageHeading.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

FdPageHeading.defaultProps = {
  type: undefined,
  variant: 'h2',
};

export default FdPageHeading;
