import React, { useEffect, useRef } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import FdTypography from '../FdTypography';
import FdTooltip from '../FdTooltip';
import { DIFFICULTY_OPTIONS } from '../../constants';
import { generateLoaderSpecialties } from './util';
import { getCortexCellColor } from '../../shared/cortex';

const RowHeader = ({ flip, children }) => (
  <Box
    height="24px"
    className={`flex items-center ${flip ? 'justify-end' : 'justify-start'}`}
  >
    {children}
  </Box>
);

RowHeader.propTypes = {
  flip: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const ColumnHeaders = ({ levels, flip }) => {
  return (
    <Box className={`flex ${flip ? 'flex-row-reverse' : 'flex-row'} gap-x-1`}>
      {levels?.map((l) => (
        <FdTooltip title={DIFFICULTY_OPTIONS[l - 1]}>
          <span>
            <Box key={l} className="flex items-center justify-center h-5 w-6">
              <FdTypography variant="captiontext2">{l}</FdTypography>
            </Box>
          </span>
        </FdTooltip>
      ))}
    </Box>
  );
};

ColumnHeaders.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  flip: PropTypes.bool.isRequired,
};

const onCellHover = (event) =>
  anime({
    targets: event.currentTarget,
    scale: event.type === 'mouseenter' ? 1.1 : 1,
    duration: 300,
    easing: 'easeOutQuad',
  });

const Columns = ({
  levels,
  id,
  data,
  isDarkTheme,
  defaultBorderColor,
  enableAnimation,
}) => {
  const boxRefs = useRef([]);
  useEffect(() => {
    if (enableAnimation) {
      anime({
        targets: boxRefs.current,
        scale: [
          { value: 0.1, easing: 'easeOutSine', duration: 250 },
          { value: 1, easing: 'easeInOutQuad', duration: 500 },
        ],
        opacity: [0, 1],
        delay: anime.stagger(200, { grid: [14, 5], from: 'center' }),
      });
    }
  }, [levels, data, enableAnimation]);

  return (
    <Box className="flex gap-x-1 mb-1">
      {levels?.map((l, index) => {
        const cellElement = data?.find((d) => d?.yAlias === id && d?.x === l);
        const { bgColor: cellBgColor, color: cellTextColor } =
          getCortexCellColor(cellElement?.yValue, isDarkTheme);
        const fontColor = cellTextColor || cellElement?.yColor || 'inherit';
        const content = (
          // eslint-disable-next-line no-return-assign
          <span ref={(el) => (boxRefs.current[index] = el)} key={l}>
            <Box
              key={l}
              className={`flex items-center justify-center h-6 w-6 border ${
                cellElement?.yBorderStyle
                  ? cellElement?.yBorderStyle
                  : 'border-solid'
              }`}
              style={{
                borderColor: cellElement?.yBorderColor || defaultBorderColor,
                backgroundColor: cellElement?.yBackgroundColor || cellBgColor,
                borderWidth: cellElement?.yBorderWidth,
                borderRadius: '1px',
              }}
              onMouseEnter={onCellHover}
              onMouseLeave={onCellHover}
            >
              <FdTypography
                variant="h4"
                style={{ fontSize: '8px', color: fontColor }}
              >
                {cellElement?.yValue === 0 || cellElement?.yHideValue
                  ? ''
                  : cellElement?.yValueDisplay || cellElement?.yValue}
              </FdTypography>
            </Box>
          </span>
        );

        return cellElement?.yToolTip ? (
          <FdTooltip title={cellElement?.yToolTip}>
            <span>{content}</span>
          </FdTooltip>
        ) : (
          content
        );
      })}
    </Box>
  );
};

Columns.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDarkTheme: PropTypes.bool.isRequired,
  defaultBorderColor: PropTypes.string.isRequired,
  enableAnimation: PropTypes.bool.isRequired,
};

const Row = ({
  id,
  idDisplay,
  idWidth,
  idTooltip,
  flip,
  children,
  rowHeaders,
}) => {
  const headerContent = rowHeaders?.find((rh) => rh?.id === id)?.headerContent;

  return (
    <Box
      className={`flex justify-center gap-x-1 ${
        flip ? 'flex-row' : 'flex-row-reverse'
      }`}
    >
      <RowHeader flip={flip}>
        <Box
          className={`flex items-center justify-center ${
            flip ? 'flex-row' : 'flex-row-reverse'
          }`}
        >
          <Box className="flex items-center justify-center" height="24px">
            {headerContent}
          </Box>
          <Box width={idWidth} className="flex items-center justify-center">
            <FdTooltip title={idTooltip}>
              <span>
                <FdTypography variant="captiontext2">
                  {idDisplay || id}
                </FdTypography>
              </span>
            </FdTooltip>
          </Box>
        </Box>
      </RowHeader>
      {children}
    </Box>
  );
};

Row.defaultProps = {
  rowHeaders: [],
  idTooltip: '',
  idDisplay: undefined,
  idWidth: '25px',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  idDisplay: PropTypes.node,
  idWidth: PropTypes.string,
  flip: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  rowHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  idTooltip: PropTypes.string,
};

const headerLevels = [1, 2, 3, 4, 5];

const FdCortex = ({
  specialties,
  loading,
  legends,
  rowHeaders,
  enableAnimation,
}) => {
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const borderColor = isDarkTheme ? '#40417B' : '#DBE6FF';
  const specialtiesDisplay = loading
    ? generateLoaderSpecialties(borderColor)
    : specialties;

  return (
    <>
      <Box
        id="cyber-brain"
        width="max-content"
        className="relative grid grid-cols-2 gap-x-5 gap-y-2"
      >
        <Box className="absolute top-6 bottom-1 inset-y-0 left-1/2 transform -translate-x-1/2 border-l border-dashed" />
        <ColumnHeaders levels={headerLevels} flip />
        <ColumnHeaders levels={headerLevels} flip={false} />
        {specialtiesDisplay?.map((specialty) => {
          return (
            <Box
              className={`flex  ${
                specialty.flip ? 'flex-row' : 'flex-row-reverse'
              }`}
            >
              <Box
                className="flex items-center justify-center py-1 px-2 rounded self-center"
                style={{
                  backgroundColor: loading
                    ? 'transparent'
                    : isDarkTheme
                    ? 'rgba(189, 189, 189, 1)'
                    : 'rgba(227, 230, 236, 1)',
                  transform: specialty.flip
                    ? 'rotate(270deg)'
                    : 'rotate(90deg)',
                  height: '24px',
                  width: '75px',
                }}
              >
                <FdTypography
                  variant="captiontext2"
                  style={{ color: 'rgba(0, 0, 0, 0.87)', fontWeight: 600 }}
                >
                  {specialty.specialtyName}
                </FdTypography>
              </Box>
              <Box>
                <Box>
                  {specialty?.dataSorted?.map((d) => (
                    <Row
                      id={d?.id}
                      idWidth={d?.idWidth}
                      idDisplay={d?.idDisplay}
                      tooltip={d?.data[0]?.yLabelName}
                      flip={specialty.flip}
                      rowHeaders={rowHeaders}
                      idTooltip={`${
                        specialty?.items?.find((i) => i?.alias === d?.id)?.name
                      } (${d?.id})`}
                    >
                      <Columns
                        levels={specialty.levels}
                        id={d?.id}
                        data={d?.data}
                        isDarkTheme={isDarkTheme}
                        defaultBorderColor={borderColor}
                        enableAnimation={enableAnimation}
                      />
                    </Row>
                  ))}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {legends}
    </>
  );
};

FdCortex.defaultProps = {
  loading: false,
  rowHeaders: [],
  legends: null,
  enableAnimation: true,
};

FdCortex.propTypes = {
  loading: PropTypes.bool,
  specialties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legends: PropTypes.node,
  rowHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  enableAnimation: PropTypes.bool,
};

export default FdCortex;
