import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles()((theme, { vertical, horizontal }) => ({
  badge: {
    position: 'absolute',
    backgroundColor: ColorPalette.green02,
    top: vertical === 'bottom' ? 25 : 5,
    right: horizontal === 'right' && 5,
    left: horizontal === 'left' && 5,
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
  },
  avatar: {
    backgroundColor: ColorPalette.orange02,
  },
}));

const FdBadge = ({ position, content, variant }) => {
  const [vertical, horizontal] = position.split('-');
  const { classes } = useStyles({ vertical, horizontal });

  const avatar =
    typeof content === 'string' || content instanceof String ? (
      <Avatar className={classes.avatar}> {content} </Avatar>
    ) : (
      <Avatar alt={content.alt} src={content.src} />
    );

  return (
    <Badge
      anchorOrigin={{ horizontal, vertical }}
      variant={variant}
      classes={{ badge: classes.badge }}
    >
      {avatar}
    </Badge>
  );
};

FdBadge.propTypes = {
  variant: PropTypes.oneOf(['standard', 'dot']),
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
};

FdBadge.defaultProps = {
  variant: 'dot',
  position: 'bottom-right',
};

export default FdBadge;
