import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import { useTheme, alpha, darken } from '@mui/material/styles';
import { HIGHLIGHT_CHIP_COLORS } from '../../constants';

const FdHighlightChip = ({ text, color, condensed }) => {
  const theme = useTheme();
  const textColor =
    (color && Color(color).rgb().string()) ||
    HIGHLIGHT_CHIP_COLORS[text] ||
    theme.palette.typography.secondary;

  return (
    <span
      className={`inline-flex items-center rounded break-words mr-1 mb-1 ${
        condensed
          ? 'px-1.5 py-0.5 text-[10px] font-semibold'
          : 'px-2 py-1 text-xs font-bold'
      }`}
      style={{
        backgroundColor: alpha(textColor, 0.25),
        color: darken(textColor, 0.1),
      }}
    >
      {text}
    </span>
  );
};

FdHighlightChip.propTypes = {
  text: PropTypes.string,
  condensed: PropTypes.bool,
  color: PropTypes.string,
};

FdHighlightChip.defaultProps = {
  condensed: false,
  text: '',
  color: undefined,
};

export default FdHighlightChip;
