import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Box } from '@mui/material';

const useStyles = makeStyles()(() => ({
  buttonContainer: {
    '& button': {
      marginRight: '1rem',
    },
  },
}));

const BasePageButtonContainer = ({ header, children }) => {
  const { classes } = useStyles();
  return (
    <Box className="flex justify-end w-full">
      {header && <Box mb={2}>{header}</Box>}
      <Box
        display="flex"
        alignItems="center"
        className={classes.buttonContainer}
        pb={3}
      >
        {children}
      </Box>
    </Box>
  );
};

BasePageButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node,
};

BasePageButtonContainer.defaultProps = {
  header: '',
};

export default BasePageButtonContainer;
