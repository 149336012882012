import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const FONT_WEIGHT = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

const EMPHASIS = {
  disabled: 0.3,
  medium: 0.6,
  high: 0.87,
  full: 1,
};

const customVariants = [
  'button_lg',
  'button_med',
  'button_sm',
  'captiontext1',
  'captiontext2',
];

const FdTypography = ({
  variant,
  fontWeight,
  disabled,
  emphasis,
  className,
  color,
  ...otherProps
}) => {
  const isCustomVariant = customVariants.includes(variant);

  const sxStyles = (theme) => ({
    ...theme.typography[variant],
    ...(!isCustomVariant && {
      typography: variant,
    }),
    opacity: EMPHASIS[emphasis],
    fontWeight: FONT_WEIGHT[fontWeight],
    color: disabled
      ? theme.palette.background.disabled
      : color === 'contrastText'
      ? theme.palette.primary.contrastText
      : theme.palette.typography[color] || color,
  });

  return (
    <Typography
      variant={isCustomVariant ? undefined : variant}
      sx={sxStyles}
      className={className}
      {...otherProps}
    />
  );
};

FdTypography.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body',
    'body1',
    'body2',
    'caption',
    'captiontext1',
    'captiontext2',
    'button_sm',
    'button',
    'button_med',
    'button_lg',
    'overline',
    'srOnly',
    'inherit',
  ]),
  fontWeight: PropTypes.oneOf(['light', 'regular', 'medium', 'bold']),
  emphasis: PropTypes.oneOf(['disabled', 'medium', 'high', 'full']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'placeholder',
    'disabled',
    'link',
    'contrastText',
  ]),
};

FdTypography.defaultProps = {
  variant: undefined,
  fontWeight: undefined,
  emphasis: undefined,
  disabled: undefined,
  className: undefined,
  color: 'primary',
};

export default FdTypography;
