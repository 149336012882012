import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Popper,
  Paper,
} from '@mui/material';
import Avatar from './Avatar';
import FdTypography from '../FdTypography';

const MentionsPopper = ({
  filteredUsers,
  onSelection,
  onClickAway,
  anchorEl,
  open,
}) => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal>
        <Paper>
          <List>
            {filteredUsers?.map((u) => (
              <ListItem key={u?.id} onClick={() => onSelection(u)}>
                <Box className="flex items-center gap-x-2">
                  <Avatar name={u?.alias} />
                  <ListItemText>
                    <FdTypography variant="body1">{u?.alias}</FdTypography>
                  </ListItemText>
                </Box>
              </ListItem>
            ))}
            {filteredUsers?.length === 0 && (
              <ListItem>
                <ListItemText primary="No matches found" />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
};

MentionsPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelection: PropTypes.func.isRequired,
  onClickAway: PropTypes.func.isRequired,
  filteredUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  anchorEl: PropTypes.shape({}).isRequired,
};

export default MentionsPopper;
