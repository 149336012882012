import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Alert, AlertTitle, Box, useTheme } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { fdProColors } from '../../themes/fd-pro-colors';

const colourMap = {
  default: fdProColors.alert.defaultLight,
  info: fdProColors.alert.infoLight,
  success: fdProColors.alert.successLight,
  warning: fdProColors.alert.warningLight,
  error: fdProColors.alert.errorLight,
};

const colourIconMap = {
  default: fdProColors.alert.defaultDark,
  info: fdProColors.alert.infoDark,
  success: fdProColors.alert.successDark,
  warning: fdProColors.alert.warningDark,
  error: fdProColors.alert.errorDark,
};

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()((theme, { variant, alertTitle }) => ({
  root: {
    padding: alertTitle ? '16px 18px' : '14px 18px',
    borderRadius: '8px',
    backgroundColor: colourMap[variant],
    '& svg': {
      fill: colourIconMap[variant],
    },
  },
  titleRoot: {
    margin: '0px 0px 8px 0px',
    color: theme?.palette?.alert?.main,
    ...theme?.typography?.subtitle1,
  },
  icon: {
    marginRight: '10px',
    padding: 0,
  },
  message: {
    padding: 0,
    marginTop: alertTitle ? 0 : '2px',
    color: theme?.palette?.alert?.main,
    width: '100%',
    ...theme?.typography?.body2,
  },
  defaultIcon: {
    color: theme?.palette?.alert?.main,
  },
}));

const FdAlert = ({ message, variant, alertTitle, customIcon, ...props }) => {
  const { classes } = useStyles({ variant, alertTitle });
  const theme = useTheme();

  const iconMap = {
    default: <RadioButtonUncheckedIcon className={classes.defaultIcon} />,
    info: <InfoOutlinedIcon />,
    success: <CheckCircleOutlineIcon />,
    warning: <ReportProblemOutlinedIcon />,
    error: <ErrorOutlineIcon />,
  };

  return (
    <Alert
      {...props}
      severity={variant === 'default' ? undefined : variant}
      icon={!customIcon && iconMap[variant]}
      classes={{
        root: classes.root,
        icon: classes.icon,
        message: classes.message,
      }}
    >
      {alertTitle && (
        <Box display="flex">
          {customIcon && (
            <Box mr={1} style={{ color: theme?.palette[variant]?.dark }}>
              {customIcon}
            </Box>
          )}
          <AlertTitle classes={{ root: classes.titleRoot }}>
            {alertTitle}
          </AlertTitle>
        </Box>
      )}
      <Box display="flex" alignItems="center">
        {customIcon && !alertTitle && (
          <Box mr={1} style={{ color: theme?.palette[variant]?.dark }}>
            {customIcon}
          </Box>
        )}
        {message}
      </Box>
    </Alert>
  );
};

FdAlert.propTypes = {
  variant: PropTypes.oneOf(['default', 'info', 'success', 'warning', 'error']),
  alertTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  customIcon: PropTypes.node,
};

FdAlert.defaultProps = {
  variant: undefined,
  alertTitle: undefined,
  customIcon: undefined,
};

export default FdAlert;
