import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DesktopDateTimePicker,
  renderTimeViewClock,
} from '@mui/x-date-pickers';
import { InputLabel } from '@mui/material/';
import { makeStyles } from 'tss-react/mui';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles()((theme) => ({
  helperText: {
    marginLeft: '0',
    width: 'max-content',
  },
  labelRoot: {
    fontWeight: 600,
    color:
      (theme.palette.typography && theme.palette.typography.primary) ||
      ColorPalette.black00,
    overflowWrap: 'break-word',
  },
  disabled: {
    color: theme.palette.background.disabled,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1em',
  },
}));

const FdDateTimePicker = ({
  value,
  onChange,
  id,
  label,
  placeholder,
  readOnly,
  format,
  helperText,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={classes.container}>
        <InputLabel
          htmlFor={id}
          id={`${id}-label`}
          shrink={false}
          classes={{
            root: classes.labelRoot,
            disabled: classes.disabled,
          }}
        >
          {label}
        </InputLabel>
        <DesktopDateTimePicker
          value={value ? dayjs(value) : value}
          onChange={onChange}
          ampm={false}
          ampmInClock={false}
          readOnly={readOnly}
          format={format}
          sx={{
            width: '268px',
            height: '48px',
            marginTop: '-10px',
            borderRadius: '4px',
          }}
          closeOnSelect
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: {
              helperText,
              placeholder,
              FormHelperTextProps: {
                className: classes.helperText,
              },
              // eslint-disable-next-line react/prop-types
              error: props.error,
              // eslint-disable-next-line react/prop-types
              ...props.InputProps,
            },
          }}
          {...props}
        />
      </div>
    </LocalizationProvider>
  );
};

FdDateTimePicker.propTypes = {
  value: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disableToolbar: PropTypes.bool,
  readOnly: PropTypes.bool,
  format: PropTypes.string,
  helperText: PropTypes.string,
};

FdDateTimePicker.defaultProps = {
  format: 'DD/MM/YYYY HH:mm',
  label: '',
  id: undefined,
  placeholder: undefined,
  value: undefined,
  disableToolbar: false,
  readOnly: false,
  helperText: '',
};

export default FdDateTimePicker;
