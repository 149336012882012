import { makeStyles } from 'tss-react/mui';

const drawerWidth = 240;
const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  listItemIcon: {
    color:
      theme.palette.mode === 'dark'
        ? '#9E9E9E'
        : theme.palette.iconography.primary,
    paddingRight: theme.spacing(2),
    minWidth: 'unset',
  },
  listItemIconSelected: {
    color: theme.palette.iconography.primary,
    paddingRight: theme.spacing(2),
    minWidth: 'unset',
  },
  logo: {
    width: '199px',
    height: '150.99px',
    margin: '24px 20px',
  },
  logoClosed: {
    width: '64px',
    height: '150.99px',
    margin: '24px 20px',
  },
  logoBottomContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  logoBottom: {
    backgroundRepeat: 'no-repeat',
    width: '188px',
    height: '53px',
    margin: '0',
    boxSizing: 'border-box',
  },
  chevron: {
    width: '40px',
    height: '40px',
    right: '-29px',
    backgroundColor: theme.palette.background.default,
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
    },
    zIndex: theme.zIndex.drawer + 2,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowY: 'unset',
    '&:hover #toolbar': {
      display: 'flex',
    },
  },
  drawerPaper: {
    borderRight: 'none',
    boxShadow:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
  },
  drawerOpen: {
    overflowY: 'unset',
    overflowX: 'unset',
    backgroundColor: theme.palette.background.sidebar,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  drawerClose: {
    overflowY: 'unset',
    overflowX: 'unset',
    backgroundColor: theme.palette.background.sidebar,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    overflowY: 'unset',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: 2,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: '0 !important',
    minHeight: '0 !important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  heavyText: {
    fontWeight: '600',
  },
  nested: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    borderLeft: '4px solid transparent',
  },
  nestedClosed: {
    marginTop: theme.spacing(1),
    display: 'none',
  },
  listClosed: {
    overflowX: 'hidden',
    paddingTop: '0',
  },
  listOpen: {
    overflowX: 'hidden',
    paddingTop: '0 !important',
    color:
      theme.palette.mode === 'dark'
        ? '#9E9E9E'
        : theme.palette.typography.primary,
  },
  selectedItem: {
    borderLeft: `4px solid ${theme.palette.listItem.selectedStroke}`,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    width: '95% !important',
    backgroundColor: theme.palette.listItem.selectedBackground,
    marginTop: theme.spacing(1),
    height: '48px',
    color: theme.palette.listItem.color,
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
    '& svg': {
      fill: theme.palette.listItem.color,
    },
  },
  selectedParentItem: {
    overflowX: 'hidden',
    borderLeft: '4px solid transparent',
    backgroundColor: theme.palette.inputs.backgroundHover,
    marginTop: theme.spacing(1),
    height: '48px',
  },
  selectedParentItemClosed: {
    overflowX: 'hidden',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.inputs.backgroundHover,
    marginTop: theme.spacing(1),
    height: '48px',
    '& svg': {
      fill:
        theme.palette.mode === 'dark'
          ? '#3333FF'
          : theme.palette.secondary.main,
    },
  },
  item: {
    borderLeft: '4px solid transparent',
    marginTop: theme.spacing(1),
    height: '48px',
  },
  hidden: {
    fontSize: '0',
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
