import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Typography } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
// import ClipLoader from 'react-spinners/ClipLoader';

import './InformationBox.css';

/*
  Variant is one of:
    default
    success
    warning
    information

    examples:
    variant="warning"
    icon=<ErrorOutlineIcon />

    variant="warning"
    icon=<StopIcon />

    variant="success"
    icon=<CheckCircleOutlineIcon />

    variant="info"
    imageUrl="/spinner.svg"

*/

const InformationBox = ({
  status,
  message,
  variant,
  imageUrl,
  allowNullMessage,
  messageIsLink,
  messageURL,
  titleId,
}) => {
  if (!message && allowNullMessage !== true) {
    return null;
  }

  let tempTitleId = titleId;

  if (!titleId) {
    tempTitleId = 'information-box-title';
  }

  return (
    <Alert severity={variant} icon={imageUrl} id="information-box">
      <AlertTitle id={tempTitleId}>{status}</AlertTitle>
      {messageIsLink && message && (
        <Typography variant="body2" display="inline" ml="5px">
          <a href={messageURL}>{message}</a>
        </Typography>
      )}
      {!messageIsLink && (
        <Typography variant="body2" display="inline" ml="5px">
          {message}
        </Typography>
      )}
    </Alert>
  );
};

InformationBox.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.string,
  imageUrl: PropTypes.string,
  allowNullMessage: PropTypes.bool,
  messageIsLink: PropTypes.bool,
  messageURL: PropTypes.string,
  titleId: PropTypes.string,
};

InformationBox.defaultProps = {
  variant: undefined,
  message: undefined,
  status: undefined,
  imageUrl: undefined,
  allowNullMessage: false,
  messageIsLink: false,
  messageURL: undefined,
  titleId: undefined,
};

export default InformationBox;
