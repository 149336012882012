import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, FormLabel, RadioGroup, Radio } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { ColorPalette } from '../../shared/colors';

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  formControl: {
    boxSizing: 'border-box',
    marginRight: 0,
    minWidth: '70%',
  },
  formLabel: {
    color: theme.palette.typography.primary,
    ...theme.typography.subtitle1,
    [`&.${classes.focused}`]: {
      color: theme.palette.typography.primary,
    },
  },
  focused: {},
  errored: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeft: `4px solid ${ColorPalette.red00}`,
    paddingLeft: theme.spacing(2),
  },
  errorText: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
    margin: 0,
    color: ColorPalette.red01,
    letterSpacing: 0,
  },
  radioGroupRoot: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  radioText: {
    color: theme.palette.typography.primary,
    ...theme.typography.body2,
  },
  radioRoot: {
    padding: theme.spacing(1),
    color: 'theme.palette.inputs.selectBorder',
  },
  radioChecked: {
    color: theme.palette.primary.main,
  },
}));

const FdRadioScale = ({
  id, label, onChange, error, startText, endText, scale,
}) => {
  const { classes } = useStyles();
  const [value, setValue] = useState('');
  const options = _.times(scale, (i) => i + 1);

  return (
    <FormControl
      classes={error ? {
        root: classes.errored,
      }
        : {
          root: classes.formControl,
        }}
    >
      <FormLabel classes={{ root: classes.formLabel, focused: classes.focused }} component="legend">{label}</FormLabel>
      <RadioGroup
        id={id}
        aria-label={label}
        name={id}
        value={value}
        onChange={(event) => {
          onChange({
            id,
            value: event.target.value,
          });
          setValue(event.target.value);
        }}
        classes={{
          root: classes.radioGroupRoot,
        }}
      >
        <span className={classes.radioText} style={{ marginRight: '8px' }}>
          {startText}
        </span>
        {options.map((_value, idx) => (
          <Radio
            checked={value == _value}
            key={_value + idx}
            value={_value}
            classes={error ? {
              root: [classes.errorText, classes.radioRoot].join(' '),
            } : {
              root: classes.radioRoot,
              checked: classes.radioChecked,
            }}
          />

        ))}
        <span className={classes.radioText} style={{ marginLeft: '8px' }}>
          {endText}
        </span>
      </RadioGroup>
      {error
        && (
          <FormHelperText
            className={classes.errorText}
          >
            {error}
          </FormHelperText>
        )}
    </FormControl>
  );
};

FdRadioScale.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
};

FdRadioScale.defaultProps = {
  label: undefined,
  error: undefined,
};

export default FdRadioScale;
