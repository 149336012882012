import { makeStyles } from 'tss-react/mui';

const drawerWidth = 400;

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  cardContent: {
    paddingRight: 0,
  },
  chatHeading: {
    wordWrap: 'break-word',
  },
  messageDisplayBox: {
    margin: '8px 16px 8px 0',
    '& div': {
      '& div': {
        '& div': {
          '& svg': {
            width: '31px !important',
          },
          '& div': {
            '& img': {
              width: '20px !important',
            },
          },
        },
      },
    },
  },
  message: {
    backgroundColor: theme.palette.text.background,
    borderRadius: '4px',
    outline: `1px solid ${theme.palette.table.border}`,
    color: theme.palette.typography.secondary,
    whiteSpace: 'pre-line',
    overflowWrap: 'anywhere',
  },
  dateCaption: {
    color: `${theme.palette.typography.placeholder} !important`,
  },
}));

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export default useStyles;
