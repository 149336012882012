import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

const FdSkeleton = ({ loading, variant, height, children, ...props }) => {
  return loading ? (
    <Skeleton variant={variant} height={height} {...props} />
  ) : (
    children
  );
};

FdSkeleton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

FdSkeleton.defaultProps = {
  loading: false,
  variant: 'rect',
  height: 40,
  width: '100%',
};

export default FdSkeleton;
