import useInitializeAAFCStore from './useInitializeAAFCStore';
import useInitializeAffiliatedStore from './useInitializeAffiliatedStore';
import useInitializeDefaultEventStore from './useInitializeDefaultEventStore';
import useInitializeDefaultStore from './useInitializeDefaultStore';
import useInitializeProductsStore from './useInitializeProductsStore';
import useInitializeCSCStore from './useInitializeCSCStore';

const useInitializeGlobalStore = () => {
  useInitializeDefaultStore();
  useInitializeAffiliatedStore();
  useInitializeAAFCStore();
  useInitializeCSCStore();
  useInitializeProductsStore();
  useInitializeDefaultEventStore();
};

export default useInitializeGlobalStore;
