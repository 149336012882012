import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import initHelpHero from 'helphero';
import { ME_QUERY } from '../queries';
import { HELP_HERO_TOUR_ID } from '../constants';

const HelpHeroProvider = ({ useQuery, helpTourId, children }) => {
  useQuery(ME_QUERY, {
    onCompleted: (data) => {
      const helpHero = initHelpHero(helpTourId || HELP_HERO_TOUR_ID);
      helpHero.identify(data.me.id);
    },
  });

  return <Box>{children}</Box>;
};

HelpHeroProvider.propTypes = {
  /** help tour id for help hero */
  helpTourId: PropTypes.string,
  /** useQuery instance of apollo client */
  useQuery: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

HelpHeroProvider.defaultProps = {
  helpTourId: '',
};

export default HelpHeroProvider;
