import React, { createRef, useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import FdTypography from '../FdTypography';
import FdProgress from '../FdProgress';
import FdButton from '../FdButton';
import Controls from './Controls';
import FdLoadingSpinner from '../FdLoading/FdLoadingSpinner';

// WMKS Cannot be used as a part of the normal asset pipeline.
// We must load the script as a file path so it can be injected into the page
import WMKS_PATH from '../../shared/wmks';

window.$ = require('jquery');
require('jquery-ui-bundle');

const useStyles = makeStyles()(() => ({
  wmksContainer: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
}));

function Console({ MKSTicket, keepAwakeUrl }) {
  const { WMKS } = window;
  const [showControls, setShowControls] = useState(false);
  const [clipboardData, setClipboardData] = useState('');
  const [connected, setConnected] = useState(false);
  const [error, setErrored] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [wmks, setwmks] = useState(undefined);
  const wmksDisplayRef = createRef();
  const { classes } = useStyles();

  const onError = (message) => {
    setConnected(false);
    setErrored(true);
    setErrorMessage(message);
    wmks?.destroy();
  };

  const onClipboard = (_evt, data) => {
    setClipboardData(data);
  };

  const pasteClipboard = (data) => {
    // To paste we just send the keyboard inputs as if the user was typing.
    wmks?.sendInputString(data);
  };

  const updateClipboard = () => {
    // To get the copied text the canvas needs to be exited and re-entered
    wmks?.ungrab();
    wmks?.grab();
  };

  const ctrlAltDelete = () => {
    wmks?.sendCAD();
  };

  const enterFullscreen = () => {
    if (wmks?.canFullScreen()) {
      wmks?.enterFullScreen();
    }
  };

  const onStateChange = (_evt, data) => {
    switch (data.state) {
      case WMKS.CONST.ConnectionState.CONNECTING:
        setConnected(false);
        break;
      case WMKS.CONST.ConnectionState.CONNECTED:
        setConnected(true);
        break;
      case WMKS.CONST.ConnectionState.DISCONNECTED:
        setConnected(false);
        onError('Disconnected');
        break;
      default:
        break;
    }
  };

  const setupWMKS = (wmksDisplay) => {
    try {
      const wmksVDI = WMKS.createWMKS(wmksDisplay.id, {});
      wmksVDI.register(
        WMKS.CONST.Events.CONNECTION_STATE_CHANGE,
        onStateChange,
      );
      wmksVDI.register(WMKS.CONST.Events.ERROR, function conError() {
        onError('Connection Error');
      });
      wmksVDI.register(WMKS.CONST.Events.COPY, onClipboard);
      wmksVDI.connect(MKSTicket);
      setwmks(wmksVDI);
    } catch (e) {
      onError('MKS Ticket is Invalid');
    }
  };

  const handleKeyPress = useCallback((e) => {
    // Toggle Clipboard drawer
    if (e.shiftKey && e.altKey && e.ctrlKey) {
      setShowControls((state) => !state);
    }
  }, []);

  // Listen for key events
  useEffect(() => {
    document
      .getElementById('wmksContainer')
      ?.addEventListener('keydown', handleKeyPress);
    return () => {
      document
        .getElementById('wmksContainer')
        ?.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Rescale VDI when windows is resized
  useEffect(() => {
    const rescale = () => {
      wmks?.updateScreen();
    };

    window.addEventListener('resize', rescale);
    return () => {
      window.removeEventListener('resize', rescale);
    };
  }, [wmks]);

  useEffect(() => {
    // Setup WMKS on mount
    setupWMKS(wmksDisplayRef.current);

    const keepAliveRequest = async () => {
      try {
        const response = await fetch(keepAwakeUrl);
        if (!response.ok) {
          throw new Error('Keep Alive request failed');
        }
      } catch (e) {
        // onError('Failed to send Keep Alive request');
        throw new Error('Failed to send Keep Alive request');
      }
    };

    // silently send keep awake every 5 minutes
    const keepAliveInterval = setInterval(keepAliveRequest, 300000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(keepAliveInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box>
        {connected || error || (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <FdTypography variant="h4">Connecting to VM...</FdTypography>
            <Box m={1} />
            <FdProgress />
          </Box>
        )}
        {error ? (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <FdTypography variant="h4">{errorMessage}</FdTypography>
            <Box m={4} />
            <FdButton size="large" onClick={() => window.location.reload()}>
              Reconnect
            </FdButton>
          </Box>
        ) : (
          <div
            className={classes.wmksContainer}
            id="wmksContainer"
            ref={wmksDisplayRef}
            role="application"
          />
        )}
      </Box>
      <Controls
        enterFullscreen={enterFullscreen}
        updateClipboard={updateClipboard}
        setShowControls={setShowControls}
        pasteClipboard={pasteClipboard}
        ctrlAltDelete={ctrlAltDelete}
        showControls={showControls}
        clipboardData={clipboardData}
      />
    </div>
  );
}

Console.propTypes = {
  MKSTicket: PropTypes.string.isRequired,
  keepAwakeUrl: PropTypes.string.isRequired,
};

const VDIConsole = (props) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = WMKS_PATH;
    script.onload = () => {
      setIsScriptLoaded(true);
    };
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return isScriptLoaded ? <Console {...props} /> : <FdLoadingSpinner />;
};

export default VDIConsole;
