import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Divider,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import Lock from '@mui/icons-material/Lock';
import { ColorPalette } from '../../shared/colors';
import FdAvatar from '../FdAvatar';
import FdAvatarGroup from '../FdAvatarGroup';
import FdChip from '../FdChip';
import FdButton from '../FdButton';
import FdTypography from '../FdTypography';

const useStyles = makeStyles()(() => ({
  cardRoot: {
    borderRadius: '10px',
    marginBottom: '1rem',
    width: '272px',
  },
  mediumWidth: {
    width: '328px',
  },
  challenge: {
    borderTop: `10px solid ${ColorPalette.green01}`,
  },
  cardHeaderRoot: {
    paddingBottom: '0',
  },
  disabled: {
    opacity: 0.5,
  },
}));

const CheckIcon = () => <Check />;
const LockIcon = () => <Lock />;

const FdCardMini = ({
  type,
  iconContent,
  themeColor,
  title,
  subTitle,
  difficultyLabel,
  description,
  avatars,
  status,
  mediumWidth,
  cardAction,
  dependencyLink,
  actionButtonLabel,
}) => {
  const { classes, cx } = useStyles();

  const themeColorr = themeColor || ColorPalette.green01;

  return (
    <Card
      className={cx({
        [classes.cardRoot]: true,
        [classes.challenge]: type === 'challenge',
        [classes.mediumWidth]: mediumWidth,
      })}
    >
      <Grid
        justifyContent="space-between"
        container
        className={cx({
          [classes.disabled]: status === 'disabled' || status === 'locked',
        })}
      >
        <CardHeader
          className={cx({
            [classes.cardHeaderRoot]: true,
          })}
          avatar={
            <FdAvatar
              variant="square"
              content={iconContent}
              color={themeColorr}
              size="medium"
            />
          }
          title={<FdTypography variant="subtitle1">{title}</FdTypography>}
          subheader={
            <FdTypography variant="button_sm">{subTitle}</FdTypography>
          }
        />
        <Box ml={2} mt={2} mr={2}>
          <FdChip
            color="default"
            size="small"
            label={difficultyLabel}
            labelType
          />
        </Box>
      </Grid>

      <CardContent
        className={cx({
          [classes.disabled]: status === 'disabled' || status === 'locked',
        })}
      >
        <FdTypography variant="body2" color="secondary">
          {description}
        </FdTypography>
        {avatars?.length > 0 && (
          <Box ml={0} mt={1} mr={1}>
            <FdAvatarGroup
              spacing={10}
              max={4}
              variant="circular"
              avatars={avatars}
            />
          </Box>
        )}
      </CardContent>
      <Divider />

      {(status === 'default' || status === 'completed') &&
        type === 'challenge' && (
          <Box m={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={2}>
                <FdButton size="small" variant="tertiary" onClick={cardAction}>
                  {actionButtonLabel}
                </FdButton>
              </Grid>
              {status === 'completed' && type === 'challenge' && (
                <Grid item xs={8}>
                  <FdChip
                    icon={CheckIcon}
                    color="success"
                    size="small"
                    label="Completed"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        )}

      {status === 'locked' && type === 'challenge' && (
        <Box m={1} mt={2} mb={2}>
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xs={3}>
              <FdChip
                icon={LockIcon}
                color="error"
                size="small"
                label="Locked"
              />
            </Grid>
            <Grid item xs={7}>
              {dependencyLink}
            </Grid>
          </Grid>
        </Box>
      )}

      {status === 'disabled' && type === 'challenge' && (
        <Box m={2}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <FdChip
                icon={LockIcon}
                color="default"
                size="small"
                label="Disabled"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Card>
  );
};

FdCardMini.propTypes = {
  type: PropTypes.oneOf(['challenge']),
  iconContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  themeColor: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  difficultyLabel: PropTypes.string,
  description: PropTypes.string,
  avatars: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  status: PropTypes.oneOf([
    'completed',
    'locked',
    'disabled',
    'cantRun',
    'default',
  ]),
  mediumWidth: PropTypes.bool,
  cardAction: PropTypes.func,
  dependencyLink: PropTypes.node,
  actionButtonLabel: PropTypes.string,
};

FdCardMini.defaultProps = {
  type: 'challenge',
  iconContent: undefined,
  themeColor: undefined,
  title: undefined,
  subTitle: undefined,
  difficultyLabel: undefined,
  description: undefined,
  avatars: undefined,
  status: undefined,
  mediumWidth: false,
  cardAction: () => {},
  dependencyLink: undefined,
  actionButtonLabel: 'View',
};

export default FdCardMini;
