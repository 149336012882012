import React, { useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSnapshot } from 'valtio';
import shortid from 'shortid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChatMessage from './ChatMessage';
import AddComment from './AddComment';
import { initialValues, validationSchema } from './validation-schema';
import { scrollToAnchor } from './utils';
import globalStore from '../../store';
import FdTypography from '../FdTypography';
import BlueFillerAniImage from './BlueFillerIAniImage';

const ChatStream = ({
  allMessages,
  headerHeight,
  userList,
  onAddMessage,
  onUpdateMessage,
  onDeleteMessage,
  onCancelDeleteMessage,
  onSetReaction,
  viewOnly,
  hideReactions,
  noRoles,
  currentRole,
  adminView,
  useReaction,
  teams,
  reactionsViewOnly,
  size,
  shortMode,
  placeholder,
  captionText,
  warningToastMessage,
  messageCharLimit,
}) => {
  const listRef = useRef(null);
  const rowHeights = useRef({});
  const [showScrollTo, setShowScrollTo] = useState(false);
  const globalSnap = useSnapshot(globalStore);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, control } = reactHookFormMethods;

  const {
    fields: messages,
    append: addMessage,
    remove: removeMessage,
    update: updateMessage,
  } = useFieldArray({
    control,
    name: 'messages',
  });
  const lastIndex = messages.length - 1;

  useEffect(
    () =>
      reset({
        messages: allMessages,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allMessages],
  );

  const getRowHeight = (index) => {
    return rowHeights.current[index] || 140;
  };

  const scrollToBottom = () => {
    if (listRef.current) {
      setShowScrollTo(false);
      listRef.current.scrollToItem(messages.length - 1, 'end');
      scrollToAnchor('last-msg-anchor');
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  const handleScroll = () => {
    const container = listRef.current;
    if (!container) return;
    const isScrolledToBottom = container.scrollTop >= 0;
    setShowScrollTo(!isScrolledToBottom);
  };

  const setRowHeight = (index, _size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = {
      ...rowHeights.current,
      [index]: _size + (size === 'small' ? 35 : 24),
    };
  };
  const getBgColor = (theme) =>
    theme?.palette?.type === 'dark' ? '#111010' : 'rgba(0, 0, 0, 0.03)';

  return (
    <Box className="px-1">
      <FormProvider {...reactHookFormMethods}>
        <Box
          className="flex flex-col"
          style={{ height: `calc(100vh - ${headerHeight}px)` }}
        >
          {messages?.length > 0 ? (
            <VariableSizeList
              ref={listRef}
              height={window.innerHeight}
              width="100%"
              itemCount={messages.length}
              itemSize={(index) => getRowHeight(index)}
              estimatedItemSize={300}
              onScroll={handleScroll}
            >
              {({ index, style }) => (
                <div style={style}>
                  <ChatMessage
                    message={messages[index]}
                    index={index}
                    setRowHeight={setRowHeight}
                    lastIndex={lastIndex}
                    onDelete={() => {
                      const { messageId, keyId } = messages[index] || {};
                      removeMessage(index);
                      onDeleteMessage(messageId, keyId);
                    }}
                    onDeleteCancel={() => {
                      onCancelDeleteMessage();
                    }}
                    userList={userList}
                    updateMessage={updateMessage}
                    onUpdateMessage={onUpdateMessage}
                    onSetReaction={onSetReaction}
                    viewOnly={viewOnly}
                    noRoles={noRoles}
                    hideReactions={hideReactions}
                    adminView={adminView}
                    useReaction={useReaction}
                    reactionsViewOnly={reactionsViewOnly}
                    size={size}
                    shortMode={shortMode}
                    messageCharLimit={messageCharLimit}
                  />
                </div>
              )}
            </VariableSizeList>
          ) : (
            <Box className="flex flex-col items-center justify-center h-screen w-full">
              <Box height="200px" width="200px">
                <BlueFillerAniImage />
              </Box>
              <Box className="text-center mt-6">
                <FdTypography variant="body2" color="secondary">
                  No messages yet.
                  <br />
                  <br />
                  {!viewOnly && 'Start the conversation by sending a message!'}
                </FdTypography>
              </Box>
            </Box>
          )}
          {showScrollTo && (
            <IconButton
              onClick={scrollToBottom}
              color="primary"
              aria-label="scroll to bottom"
              sx={(theme) => ({
                position: 'absolute',
                color: theme.palette?.primary?.main,
                bottom: `${(listRef?.current?.offsetHeight || 0) + 170}px`,
                width: '32px',
                height: '32px',
                backgroundColor: getBgColor(theme),
                border: `2px solid ${theme.palette?.primary?.main}`,
                boxShadow: '0 0 0 1px #000',
                padding: '2px',
                left: '50%',
                transform: 'translateX(-50%)',
                '&:hover': {
                  backgroundColor: getBgColor(theme),
                },
                '&:focus': {
                  backgroundColor: getBgColor(theme),
                },
              })}
            >
              <ArrowDownwardIcon />
            </IconButton>
          )}
          {!viewOnly && (
            <AddComment
              onAddComment={(message, imagePreview, imageFile) => {
                const team = teams?.find((t) =>
                  t?.team?.members.items.some(
                    (member) => member?.userId === globalSnap?.userId,
                  ),
                )?.team;
                const keyId = shortid.generate(); // temporary id to track new messages
                addMessage({
                  comment: message,
                  userName: globalSnap?.userAlias,
                  dateTime: new Date(),
                  image: imagePreview,
                  role: currentRole,
                  userId: globalSnap?.userId,
                  team,
                  keyId,
                });
                onAddMessage(message, imageFile, keyId);
              }}
              userList={userList}
              shortMode={shortMode}
              placeholder={placeholder}
              captionText={captionText}
              warningToastMessage={warningToastMessage}
              messageCharLimit={messageCharLimit}
            />
          )}
        </Box>
      </FormProvider>
    </Box>
  );
};

ChatStream.defaultProps = {
  headerHeight: 260,
  userList: [],
  viewOnly: false,
  hideReactions: false,
  noRoles: false,
  currentRole: '',
  adminView: false,
  useReaction: () => null,
  teams: [],
  reactionsViewOnly: false,
  size: 'big',
  shortMode: false,
  placeholder: '',
  captionText: '',
  warningToastMessage: () => {},
  messageCharLimit: 500,
};

ChatStream.propTypes = {
  allMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headerHeight: PropTypes.number,
  userList: PropTypes.arrayOf(PropTypes.string),
  onAddMessage: PropTypes.func.isRequired,
  onUpdateMessage: PropTypes.func.isRequired,
  onDeleteMessage: PropTypes.func.isRequired,
  onCancelDeleteMessage: PropTypes.func.isRequired,
  onSetReaction: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  hideReactions: PropTypes.bool,
  noRoles: PropTypes.bool,
  currentRole: PropTypes.string,
  adminView: PropTypes.string,
  useReaction: PropTypes.func,
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  reactionsViewOnly: PropTypes.bool,
  size: PropTypes.oneOf(['big', 'small']),
  shortMode: PropTypes.bool,
  placeholder: PropTypes.string,
  captionText: PropTypes.string,
  warningToastMessage: PropTypes.func,
  messageCharLimit: PropTypes.number,
};

export default ChatStream;
