import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { makeStyles } from 'tss-react/mui';
import './react-mde-preview.css';

const useStyles = makeStyles()((theme) => ({
  markdownContent: {
    padding: '10px',
    '& pre': {
      whiteSpace: 'pre-line',
      color:
        theme?.palette?.type === 'dark'
          ? theme?.palette?.background?.default
          : theme?.palette?.typography?.secondary,
    },
    '& ul': {
      listStyle: 'disc',
    },
  },
}));

const FdMarkdownRender = ({ markdown, ...props }) => {
  const { classes } = useStyles();
  return (
    <Box className="mde-preview">
      <Box
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        classes={{
          root: classes.markdownContent,
        }}
        style={{ fontFamily: 'Montserrat, sans-serif' }}
        className="mde-preview-content"
      >
        <ReactMarkdown
          linkTarget="_blank"
          // eslint-disable-next-line react/no-children-prop
          children={markdown}
          remarkPlugins={[gfm]}
        />
      </Box>
    </Box>
  );
};

FdMarkdownRender.propTypes = {
  markdown: PropTypes.string.isRequired,
  /** markdown value */
};

export default React.memo(FdMarkdownRender);
