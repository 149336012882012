import { getUrl } from 'aws-amplify/storage';

export const createImageFileFromUrl = async (imageUrl, fileName) => {
  // Fetch the image data from the URL
  const response = await fetch(imageUrl);
  const imageData = await response.blob();

  // Create a File object from the Blob
  const file = new File([imageData], fileName, {
    type: response.headers.get('content-type'),
  });

  return file;
};

export const getPreSignedUrl = async (key) => {
  const res = await getUrl({
    path: `public/${key}`,
  });
  return res.url.toString();
};
