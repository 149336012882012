import { proxy } from 'valtio';

// initial state of events
const initialValues = {
  events: [],
};

// store definition
const globalEventStore = proxy({
  ...initialValues,
});

// reset store to its initial state
export const resetGlobalEventStore = () => {
  globalEventStore.events = [...initialValues.events];
};

export default globalEventStore;
