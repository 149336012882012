import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputLabel, InputAdornment, Chip } from '@mui/material';
import ChipInput from 'material-ui-chip-input';
import useStyles from './styles';

const FdMultiChipTextField = ({
  id,
  label,
  variant,
  startAdornment,
  endAdornment,
  fullWidth,
  required,
  disabled,
  width,
  values,
  setValues,
  onDelete,
  ...props
}) => {
  const { classes } = useStyles();
  const customWidth = width || '307px';
  return (
    <Box
      display={fullWidth ? 'flex' : 'inline-flex'}
      flexDirection="column"
      width={fullWidth ? 'auto' : customWidth}
    >
      {id && label && (
        <InputLabel
          htmlFor={id}
          data-cy={`label-for-${id}`}
          classes={{
            root: classes.labelRoot,
            disabled: classes.disabled,
          }}
          disabled={disabled}
        >
          {label}
          {!required && (
            <span
              className={classes.optionalLabel}
              style={{ opacity: disabled && '30%' }}
            >
              &nbsp;optional
            </span>
          )}
        </InputLabel>
      )}
      <ChipInput
        {...props}
        id={id}
        value={values}
        variant={variant}
        fullWidth={fullWidth}
        FormHelperTextProps={{
          classes: {
            root: classes.formhelperspacing,
          },
        }}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
        onAdd={(chip) =>
          setValues([
            ...values,
            ...chip.split(/,| /).filter((c) => c.trim().length > 0),
          ])
        }
        onDelete={(chip) => {
          setValues([...values.filter((email) => email !== chip)]);
          onDelete?.(chip);
        }}
        chipRenderer={({ value, handleDelete }, key) => (
          <Chip
            label={value}
            key={key}
            onDelete={handleDelete}
            sx={{ margin: '0 0.3rem 0.3rem 0' }}
          />
        )}
        classes={{
          root: classes.textFieldRoot,
          chipContainer: classes.chipContainer,
          input: classes.chipInput,
          chip: classes.chip,
        }}
      />
    </Box>
  );
};

FdMultiChipTextField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  setValues: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Required or not */
  required: PropTypes.bool,
  /** Disabled or not */
  disabled: PropTypes.bool,
  /** Custom width */
  width: PropTypes.string,
  onDelete: PropTypes.func,
};

FdMultiChipTextField.defaultProps = {
  values: [],
  variant: 'outlined',
  label: '',
  startAdornment: '',
  endAdornment: '',
  fullWidth: false,
  required: false,
  disabled: false,
  width: '',
  setValues: () => null,
  onDelete: undefined,
};

export default FdMultiChipTextField;
